<p-table
  #dt
  [value]="allUnitDonationTypes"
  [paginator]="true"
  [rows]="10"
  [totalRecords]="totalRecords"
  selectionMode="single"
  [lazy]="true"
  [reorderableColumns]="true"
  [rowsPerPageOptions]="[10, 20, 50]"
  (onLazyLoad)="loadData($event)"
  scrollHeight="calc(100vh - 405px)"
  [scrollable]="true" 
  styleClass="mt-3"
  dataKey="id"
>
<ng-template pTemplate="caption">
  <div class="flex flex-wrap justify-content-end gap-2">
      <p-button pRipple class="btn-rounded" label="Refresh Data" icon="pi pi-refresh" text (onClick)="refreshList()" />
      <p-button pRipple class="btn-rounded p-button-success" severity="success" label="New Donation" icon="pi pi-plus" text (onClick)="addNew()" />
  </div>
</ng-template>
  <!-- Expand Button Column -->
  <ng-template pTemplate="header">
    <tr>
      <th pFrozenColumn>ID</th>
      <th pFrozenColumn>Name</th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-data>
    <tr [pSelectableRow]="data">
      <td pFrozenColumn>{{ data.id }}</td>
      <td pFrozenColumn>{{ data.name }}</td>
      <td>
        <div class="flex align-items-center justify-content-center gap-2">
          <button 
              pButton 
              pRipple 
              type="button" 
              icon="pi pi-pencil" 
              (click)="onRowEditInit(data)" 
              class="p-button-rounded p-button-text">
          </button>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr  style="height:calc(100vh - 405px)">
        <td colspan="11" style="text-align: center;">There are no unit donation types</td>
    </tr>
  </ng-template>
</p-table>

<p-dialog 
  autoZIndex="false"
  baseZIndex="1000"
  [(visible)]="viewDialog" 
  [style]="{ width: '600px' }" 
  [header]="'Payout Event Details'" 
  (onHide)="viewDialog=false;"
  [modal]="true" 
  styleClass="p-fluid">
  
  <ng-template pTemplate="content">
    <form [formGroup]="form">
      <div class="view-transaction">
        <div class="vt-container">
          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="donationType">EVENT NAME</label><span class="text-danger">*</span>
              <input #formField pInputText id="name" type="text" class="form-control-prime" formControlName="name" name="name" />
              <div *ngIf="form.controls.name.invalid && (form.controls.name.dirty || form.controls.name.touched)" class="text text-danger">
                <div *ngIf="form.controls.name.errors.required">
                  Unit Donation Type is required
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton style="background-color: darkred;" (click)="save()" class="btn btn-primary m-r-5">SAVE</button>
  </ng-template>
</p-dialog>
<p-table
  #dt
  [value]="allUnits"
  [paginator]="true"
  [rows]="10"
  [totalRecords]="totalRecords"
  selectionMode="single"
  [(selection)]="selectedUnit" 
  [lazy]="true"
  [globalFilterFields]="['name', 'mobileNumber', 'unit.name', 'constituency.name','constituency.district.name']"
  [reorderableColumns]="true"
  [loading]="loading"
  [rowsPerPageOptions]="[10, 20, 50]"
  (onLazyLoad)="loadUnits($event)"
  [expandedRowKeys]="expandedRows" 
  (onRowExpand)="onRowExpand($event)"
  (onRowSelect)="onRowSelect($event)" 
  (onRowUnselect)="onRowUnselect($event)" 
  scrollHeight="calc(100vh - 405px)"
  [scrollable]="true" 
  styleClass="mt-3"
  dataKey="id"
>
<ng-template pTemplate="caption">
  <div class="flex flex-wrap justify-content-end gap-2">
      <p-button pRipple class="btn-rounded" label="Refresh Data" icon="pi pi-refresh" text (onClick)="refreshList()" />
      <p-button pRipple class="btn-rounded p-button-success" severity="success" label="New Unit" icon="pi pi-plus" text (onClick)="addNewUnit()" />
  </div>
</ng-template>
  <!-- Expand Button Column -->
  <ng-template pTemplate="header">
    <tr>
      <th pFrozenColumn pSortableColumn="name">Name <p-sortIcon field="name" /></th>
      <th pFrozenColumn pSortableColumn="unitNumber">Unit Number <p-sortIcon field="unitNumber" /></th>
      <th pSortableColumn="startedDate">Started Date<p-sortIcon field="startedDate" /></th>
      <th pSortableColumn="district.name" [style.width]="'270px'">District<p-sortIcon field="district.name" /></th>
      <th pSortableColumn="secretary.name">Secretary<p-sortIcon field="secretary.name" /></th>
      <th pSortableColumn="active">Status<p-sortIcon field="active" /></th>
      <th></th>
    </tr>
    <tr>
      <th>
          <p-columnFilter 
          matchMode="in"
          [showMenu]="false"
          type="text" 
          field="name" 
          placeholder="Search by name" 
          ariaLabel="Filter Name" />
      </th>
      <th>
          <p-columnFilter 
            matchMode="in"
            [showMenu]="false"
            type="text" 
            field="unitNumber" 
            placeholder="Search by Unit Number" 
            ariaLabel="Filter Unit Number" />
      </th>
      <th></th>
      <th>
        <p-columnFilter *ngIf="districts && districts.length > 0" field="districtName" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect 
                  [ngModel]="value" 
                  [options]="districts" 
                  placeholder="Any" 
                  (onChange)="filter($event.value)" 
                  optionLabel="name"
                  appendTo="body">
                      <ng-template let-option pTemplate="item">
                          <div class="inline-block vertical-align-middle">
                              <span class="ml-1 mt-1">{{ option.name }}</span>
                          </div>
                      </ng-template>
              </p-multiSelect>
          </ng-template>
        </p-columnFilter>
      </th>
      <th></th>
      <th></th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-unit let-expanded="expanded">
    <tr [pSelectableRow]="unit"  [style.color]="getRowColor(unit)">
      <td pFrozenColumn>{{ unit.name }}</td>
      <td pFrozenColumn>{{ unit.unitNumber }}</td>
      <td pFrozenColumn>{{ unit.startedDate ? moment(unit.startedDate).format("Do MMM YYYY") : "N/A" }}</td>
      <td>{{ unit.district.name }}</td>
      <td>{{ unit.secretary?.name }}</td>
      <td>{{ unit.active }}</td>
      <td>
        <div class="flex align-items-center justify-content-center gap-2">
          <button 
              pButton 
              pRipple 
              type="button" 
              icon="pi pi-pencil" 
              (click)="onRowEditInit(unit)" 
              class="p-button-rounded p-button-text">
          </button>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr  style="height:calc(100vh - 405px)">
        <td colspan="7" style="text-align: center;">There are no units matching your search criteria</td>
    </tr>
  </ng-template>

  <!-- Expanded Row Template -->
  <ng-template pTemplate="rowexpansion" let-unit>
    <tr style="background: #f0f0f0;">
      <td colspan="1"></td>
      <td colspan="4">
          <div class="p-3">
            <p-table [value]="unit.members" dataKey="id">
              <ng-template pTemplate="header">
                <tr>
                  <th>Name</th>
                  <th>Mobile Number</th>
                  <th>Membership Number</th>
                  <th>Mandalam</th>
                  <th>District</th>
                  <th>Status</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-member>
                <tr [style.color]="getMemberRowColor(member)">
                  <td>{{ member.name }}</td>
                  <td>{{ member.mobileNumber }}</td>
                  <td>{{ member.membershipNumber }}</td>
                  <td>{{ member.constituency?.name }}</td>
                  <td>{{ member.constituency?.district?.name }}</td>
                  <td>{{ member.status }}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
      </td>
      <td colspan="3"></td>
    </tr>
  </ng-template>
</p-table>
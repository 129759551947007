<p-timeline [value]="allStatuses">
  <ng-template pTemplate="marker" let-event>
    <span 
        class="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1" style="width: 2rem!important;height: 2rem!important;border-radius: 50%!important;"
        [style]="{ 'background-color': getColor(event) }">
            <i [class]="getIcon(event)"></i>
    </span>
  </ng-template>
  <ng-template pTemplate="content" let-event>
    <p>{{moment(event.statusUpdatedAt).format('Do MMM YYYY')}}</p>
  </ng-template>

  <ng-template pTemplate="opposite" let-event>
      {{ event.status }}
  </ng-template>
</p-timeline>
<div *ngIf="!unitForm"
  style="width:100%;height:calc(100vh - 205px);background-color: #fff;justify-content: center;align-items: center;display: flex;">
  <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s"
    ariaLabel="loading" />
</div>
<div *ngIf="unitForm" class="mt-4 p-l-45 p-r-45 ng-star-inserted">
  <p-tabView>
    <p-tabPanel header="UNIT INFORMATION">
      <form [formGroup]="unitForm" (ngSubmit)="save()">
        <div class="row">
          <div class="col-md-3 col-xl-3"></div>
          <div class="col-md-6 col-xl-6">
            <div class="card">
              <div class="card-body">
                <h5 class="p-t-5">UNIT INFORMATION</h5>
                <p>
                  Tell us about the unit details
                </p>
                <p-divider />
                <div class="row">
                  <div class="col-md-6 mb-3">
                    <label for="associationMembershipNumber">UNIT NAME</label><span class="text-danger">*</span>
                    <input #formField pInputText id="name" type="text" class="form-control-prime"
                      formControlName="name" name="name" />
                    <div
                      *ngIf="unitForm.controls.name.invalid && (unitForm.controls.name.dirty || unitForm.controls.name.touched)"
                      class="text text-danger">
                      <div *ngIf="unitForm.controls.name.errors.required">
                        Name is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 mb-3">
                    <label for="membershipNumber">Unit No.</label><span class="text-danger">*</span>
                    <input #formField pInputText id="unitNumber" type="text" class="form-control-prime"
                      formControlName="unitNumber" name="unitNumber" />
                    <div
                      *ngIf="unitForm.controls.unitNumber.invalid && (unitForm.controls.unitNumber.dirty || unitForm.controls.unitNumber.touched)"
                      class="text text-danger">
                      <div *ngIf="unitForm.controls.unitNumber.errors.required">
                        Unit Number is required
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6 mb-3">
                    <label for="address">Address</label><span class="text-danger">*</span>
                    <textarea #formField id="address" rows="5" cols="30" pInputTextarea class="form-control-prime"
                      formControlName="address" name="address"></textarea>
                    <div
                      *ngIf="unitForm.controls.address.invalid && (unitForm.controls.address.dirty || unitForm.controls.address.touched)"
                      class="text text-danger">
                      <div *ngIf="unitForm.controls.address.errors.required">
                        Address is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 mb-3">
                    <label for="startedDate">Started Date</label><span class="text-danger">*</span>
                      <p-calendar #formField class="form-control-prime" readonlyInput=true  formControlName="startedDate" [showIcon]="true" [iconDisplay]="'input'" inputId="buttondisplay" dateFormat="dd MM yy" name="startedDate" />
                      <div *ngIf="unitForm.controls.startedDate.invalid && (unitForm.controls.startedDate.dirty || unitForm.controls.startedDate.touched)" class="text text-danger">
                        <div *ngIf="unitForm.controls.startedDate.errors.required">
                          Date Joined is required
                        </div>
                      </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6 mb-3">
                    <label for="secretaryName">Secretary</label>
                    <div class="d-flex align-items-center">
                      <p-autoComplete 
                        [readonly]="!bEditSecretary" 
                        id="secretaryName" 
                        [style]="{'width':'100%'}" 
                        [inputStyle]="{'width':'100%'}" 
                        formControlName="secretaryName" 
                        [suggestions]="filteredSecretaries" 
                        (onSelect)="onSecretarySelected($event)" 
                        (completeMethod)="filterSecretary($event)" 
                        field="name" 
                        [dropdown]="bEditSecretary" 
                        class="form-control-prime"
                      >
                        <ng-template let-city pTemplate="item">
                          <div class="city-item">
                            <div>{{ city.name }}</div>
                          </div>
                        </ng-template>
                      </p-autoComplete>
                      <!-- Edit link next to autocomplete -->
                      <a *ngIf="!bEditSecretary" href="javascript:void(0)" (click)="editSecretary()" class="ms-2" style="text-decoration: underline; cursor: pointer;">Edit</a>
                    </div>
                    <!-- <div *ngIf="unitForm.controls.secretaryName.invalid && (unitForm.controls.secretaryName.dirty || unitForm.controls.secretaryName.touched)" class="text text-danger">
                      <div *ngIf="unitForm.controls.secretaryName.errors.required">
                        Secretary is required
                      </div>
                    </div> -->
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 mb-3">
                    <label for="secretaryName">District</label>
                      <p-autoComplete id="districtName" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" formControlName="districtName" [suggestions]="filteredDistricts" (onSelect)="onDistrictSelected($event)" (completeMethod)="filterDistrict($event)" field="name" [dropdown]="true" class="form-control-prime">
                        <ng-template let-city pTemplate="item">
                            <div class="city-item">
                            <div>{{city.name}}</div>
                            </div>
                        </ng-template>
                      </p-autoComplete>
                  </div>
                </div>
                <div class="row p-t-15">
                  <div class="col-md-6 text-end">
                    <button pButton type="submit" class="btn btn-primary">Save Unit Details</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-xl-3"></div>
        </div>
      </form>
    </p-tabPanel>
    <p-tabPanel *ngIf="editing" header="ALL PAYMENTS">
      <p-table
        #dtPayments
        [value]="allUnitPayments"
        [paginator]="true"
        [rows]="10"
        [totalRecords]="totalPaymentsCount"
        selectionMode="single"
        [lazy]="true"
        [reorderableColumns]="true"
        [(selection)]="selectedPayment" 
        (onRowSelect)="onPaymentRowSelect($event)" 
        [loading]="loading"
        [rowsPerPageOptions]="[10, 20, 50]"
        (onLazyLoad)="loadUnitPayments($event)"
        scrollHeight="calc(100vh - 405px)"
        [scrollable]="true" 
        styleClass="mt-3"
        dataKey="id"
      >
      <ng-template pTemplate="caption">
        <div class="flex flex-wrap justify-content-end gap-2">
            <p-button pRipple class="btn-rounded" label="Refresh Data" icon="pi pi-refresh" text (onClick)="refreshPaymentsList()" />
        </div>
      </ng-template>
        <!-- Expand Button Column -->
        <ng-template pTemplate="header">
          <tr>
            <th pFrozenColumn></th>
            <th pFrozenColumn pSortableColumn="paymentDate">Payment Date<p-sortIcon field="paymentDate" /></th>
            <th style="text-align:right">Total Deposit Paid</th>
            <th style="text-align:right">Total Subscription Paid</th>
            <th style="text-align:right">Total Amount Paid</th>
            <th style="text-align:left">Paid By</th>
            <th style="text-align:left">Confirmed By</th>
            <th pFrozenColumn >Reference #<p-sortIcon field="transactionReferenceNumber" /></th>
            <th pFrozenColumn pSortableColumn="status">Status<p-sortIcon field="status" /></th>
            <th></th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-payment>
          <tr [pSelectableRow]="payment">
            <td pFrozenColumn></td>
            <td pFrozenColumn>{{ moment(payment.paymentDate).format("Do MMM YYYY") }}</td>
            <td style="text-align:right">{{ payment.totalDepositAmountPaid | currency:'INR':'symbol'}}</td>
            <td style="text-align:right">{{ payment.totalWeeklySubscriptionAmountPaid | currency:'INR':'symbol'}}</td>
            <td style="text-align:right">{{ payment.totalAmountPaid | currency:'INR':'symbol'}}</td>
            <td >{{ payment.createdBy?.name }}</td>
            <td>{{ payment.confirmedBy?.name }}</td>
            <td>{{ payment.transactionReferenceNumber }}</td>
            <td [ngStyle]="{'background-color' : getBackgroundColor(payment), 'color':getColor(payment)}">{{ payment.status.toUpperCase() }}</td>
            <td>
              <div class="flex align-items-center justify-content-center gap-2">
                <!-- <button style="background-color: white !important;color:#000" pButton [raised]="true" (click)="viewTransaction(payment)" class="btn btn-primary m-r-5">VIEW</button> -->
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr  style="height:calc(100vh - 405px)">
              <td colspan="11" style="text-align: center;">There are no unit payments</td>
          </tr>
        </ng-template>
      </p-table>
    </p-tabPanel>
    <p-tabPanel *ngIf="editing" header="ALL DONATIONS">
      <p-table
        #dtDonations
        [value]="allUnitDonations"
        [paginator]="true"
        [rows]="10"
        [totalRecords]="totalDonationsCount"
        selectionMode="single"
        [(selection)]="selectedDonation" 
        [lazy]="true"
        [reorderableColumns]="true"
        [loading]="loading"
        (onRowSelect)="onDonationRowSelect($event)" 
        [rowsPerPageOptions]="[10, 20, 50]"
        (onLazyLoad)="loadUnitDonations($event)"
        scrollHeight="calc(100vh - 405px)"
        [scrollable]="true" 
        styleClass="mt-3"
        dataKey="id"
      >
      <ng-template pTemplate="caption">
        <div class="flex flex-wrap justify-content-end gap-2">
            <p-button pRipple class="btn-rounded" label="Refresh Data" icon="pi pi-refresh" text (onClick)="refreshDonationsList()" />
        </div>
      </ng-template>
        <!-- Expand Button Column -->
        <ng-template pTemplate="header">
          <tr>
            <th pFrozenColumn></th>
            <th pFrozenColumn>Donation Type</th>
            <th pFrozenColumn>Unit</th>
            <th pFrozenColumn>Event</th>
            <th pFrozenColumn style="text-align:right">Amount Paid<p-sortIcon field="amountPaid" /></th>
            <th>Payment Date</th>
            <th></th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-donation>
          <tr [pSelectableRow]="donation">
            <td pFrozenColumn></td>
            <td pFrozenColumn>{{ donation.donationType }}</td>
            <td pFrozenColumn>{{ unit?.name }}</td>
            <td pFrozenColumn>{{ donation.unitDonationType?.name }}</td>
            <td style="text-align:right">{{ donation.amountPaid | currency:'INR':'symbol'}}</td>
            <td>{{ donation.paymentDate ? moment(donation.paymentDate).format("Do MMM YYYY") : "N/A"}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr  style="height:calc(100vh - 405px)">
              <td colspan="11" style="text-align: center;">There are no donations matching your search criteria</td>
          </tr>
        </ng-template>
      </p-table>
    </p-tabPanel>
  </p-tabView>
</div>


<p-dialog 
  autoZIndex="false"
  baseZIndex="1000"
  [(visible)]="viewPaymentDialog" 
  [style]="{ width: '900px' }" 
  [header]="'Payment Details'" 
  (onHide)="viewPaymentDialog=false;"
  [modal]="true" 
  styleClass="p-fluid">
  
  <ng-template pTemplate="content">
    
    <div *ngIf="selectedPayment" class="p-b-20">
      <p-card header="Member Payments" subheader="You can see member payments in the section below.">
        <ng-container *ngFor="let memberDonation of selectedPayment.unitMemberPayments; let index = index">
          <div class="row">
            <div class="col-md-6">
              <label>{{ memberDonation.member.name }}</label>
            </div>
            <div class="col-md-6">
              <label>{{ memberDonation.totalAmount | currency:'INR':'symbol' }}</label>
            </div>
          </div>
        </ng-container>
    </p-card>
    </div>
  </ng-template>
</p-dialog>

<p-dialog 
  autoZIndex="false"
  baseZIndex="1000"
  [(visible)]="viewDonationDialog" 
  [style]="{ width: '900px' }" 
  [header]="'Donation Details'" 
  (onHide)="viewDonationDialog=false;"
  [modal]="true" 
  styleClass="p-fluid">
  
  <ng-template pTemplate="content">
    
    <div *ngIf="selectedDonation" class="p-b-20">
      <p-card header="Member Contributions" subheader="You can see member contributions in the section below.">
        <ng-container *ngFor="let memberDonation of selectedDonation.donatedByUnitMembers; let index = index">
          <div class="row">
            <div class="col-md-6">
              <label>{{ memberDonation.member.name }}</label>
            </div>
            <div class="col-md-6">
              <label>{{ memberDonation.amountPaid | currency:'INR':'symbol' }}</label>
            </div>
          </div>
        </ng-container>
    </p-card>
    </div>
  </ng-template>
</p-dialog>
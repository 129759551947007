import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Table } from 'primeng/table';
import { lastValueFrom, combineLatest, map } from 'rxjs';
import { ColumnFilter } from 'primeng/table';
import { ActivatedRoute, Router } from '@angular/router';

import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Helper from 'src/app/helper/Helper';

@Component({
  selector: 'app-list-all-transactions',
  templateUrl: './list-all-transactions.component.html',
  styleUrls: ['./list-all-transactions.component.scss']
})
export class ListAllTransactionsComponent implements OnInit {
  @ViewChild('template') imageTemplateRef: TemplateRef<any>;
  @ViewChild('dt') dataTable: Table;

  moment = moment
  environment = environment
  
  unitPayments: any[] = [];
  totalRecords: number = 0;
  loading: boolean = true;
  expandedRows = {}
  selectedMember = undefined
  units: any[] = [];
  constituencies: any[] = [];
  districts: any[] = [];
  statuses: any[] = []
  viewTransactionDialog = false
  selectedPayment = undefined
  selectedAttachment = undefined

  constructor(private httpClient: HttpClient, private route: ActivatedRoute, protected modalService: NgbModal) { }

  ngOnInit() {

    lastValueFrom(combineLatest([
      this.httpClient.get<any>(`${environment.serverUrl}/districts/all`),
      this.httpClient.get<any>(`${environment.serverUrl}/constituencies/all`),
      this.httpClient.get<any>(`${environment.serverUrl}/units/all`),
     ]).pipe(
      map(([districts,constituencies,units]) => { 
        this.districts = districts.data
        this.constituencies = constituencies.data
        this.units = units.data
      })
    ))
    this.statuses = [
      { label: 'Confirmed', value: 'Confirmed' },
      { label: 'Paid', value: 'Paid' },
      { label: 'Declined', value: 'Declined' },
    ];
  }

  loadTransactions(event: any) {
    this.loading = true;

    // Prepare the parameters for pagination, sorting, and filtering
    const params: any = {
      take: event.rows, // Number of records to take
      skip: event.first, // Starting point
      order: event.sortField || 'paymentDate', // Sorting field
      orderDir: event.sortOrder === 1 ? 'DESC' : 'ASC', // Sorting direction
    };

    // Apply filters dynamically if they exist
    if (event.filters.transactionReferenceNumber && event.filters.transactionReferenceNumber.value) {
      params.transactionReferenceNumber = event.filters.transactionReferenceNumber.value;
    }
    if (event.filters.unitName && event.filters.unitName.value) {
      params.units = event.filters.unitName.value.map(a => a.uuid).join(",")
    }
    if (event.filters.status && event.filters.status.value) {
      params.status = event.filters.status.value;
    }

    const httpParams = new HttpParams({ fromObject: params });

    this.httpClient.get(`${environment.serverUrl}/payments/unit-payments`, { params: httpParams }).subscribe(
      (result:any) => {
        this.unitPayments = result.data;
        this.unitPayments.map (mem =>{
          
        })
        this.totalRecords = result.count;
        
        this.loading = false;
      },
      (error) => {
        console.error('Error fetching Unit Payments:', error);
        this.loading = false;
      }
    );
  }

  confirmSelectedPayment(){
    Helper.showConfirmationAlert(`Are you sure?`,`You want to Confirm and Accept this payment?`,`question`,"Yes","No")
    .subscribe((result) => {
      if( result ){
        this.httpClient.post(`${environment.serverUrl}/payments/confirmed`, {paymentUUID:this.selectedPayment.uuid},).subscribe({
            next: (v:any) => {
              Helper.showMessageAlert('Success','Payment successfully confirmed!','success');
              this.viewTransactionDialog = false
              this.loadTransactions(this.dataTable.createLazyLoadMetadata());
            },
            error: (e) => {
              console.error(e);
              Helper.processError(e);
            },
            complete: () => console.info('complete')
          });
      }
    })
  }

  declineSelectedPayment(){
    Helper.showConfirmationAlert(`Are you sure?`,`You want to DECLINE this payment? This means you haven't received the payment in your account.`,`question`,"Yes","No")
    .subscribe((result) => {
      if( result ){
        this.httpClient.post(`${environment.serverUrl}/payments/declined`, {paymentUUID:this.selectedPayment.uuid},).subscribe({
          next: (v:any) => {
            Helper.showMessageAlert('Success','Payment successfully declined!','success');
            this.viewTransactionDialog = false
            this.loadTransactions(this.dataTable.createLazyLoadMetadata());
          },
          error: (e) => {
            console.error(e);
            Helper.processError(e);
          },
          complete: () => console.info('complete')
        });
      }
    })
  }

  refreshList(){
    if (this.dataTable) {
      this.expandedRows = {}
      this.loadTransactions(this.dataTable.createLazyLoadMetadata());
   }
  }
  onRowSelect(event: any) {
    this.viewTransaction(event.data)
  }
  viewTransaction(payment){
    this.selectedPayment = payment
    this.viewTransactionDialog = true

  }
  getBackgroundColor(payment){
    if( payment.status === 'Paid'){
      return '#BA8E23'
    }
    else if( payment.status === 'Confirmed'){
      return '#009688'
    }
    else if( payment.status === 'Declined'){
      return '#FF0000'
    }
  }

  getColor(payment){
    if( payment.status === 'Paid'){
      return '#FFF'
    }
    else if( payment.status === 'Confirmed'){
      return '#FFF'
    }
    else if( payment.status === 'Declined'){
      return '#FFF'
    }
  }

  getImageUrlForAttachment(attachment){
    return `${environment.serverUrl}/file/download?path=${attachment.objectName}`
  }

  openImage(attachment){
    this.selectedAttachment = attachment
    const modalRef = this.modalService.open(this.imageTemplateRef, {backdropClass: 'light-blue-backdrop', centered:true,size:'xl',keyboard:true});
  }

    getTimestamp(){
    return Date.now()
  }

}

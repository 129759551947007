import { state } from '@angular/animations';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Table } from 'primeng/table';
import { lastValueFrom, combineLatest, map } from 'rxjs';
import { ColumnFilter } from 'primeng/table';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-members',
  templateUrl: './list-members.component.html',
  styleUrls: ['./list-members.component.scss']
})
export class ListMembersComponent implements OnInit {

  @ViewChild('dt') dataTable: Table;

  environment = environment
  
  members: any[] = [];
  totalRecords: number = 0;
  loading: boolean = true;
  expandedRows = {}
  selectedMember = undefined
  units: any[] = [];
  constituencies: any[] = [];
  districts: any[] = [];
  statuses: any[] = []
  yearsFilter = []

  natureOfWorks = [
    {
      label : "Contractor", value: "Contractor",
    },
    {
      label : "Mason", value: "Mason",
    },
    {
      label : "Polisher", value: "Polisher",
    },
    {
      label : "Helper", value: "Helper", 
    }
  ]

  constructor(private http: HttpClient, private router: Router) { }

  ngOnInit() {

    this.yearsFilter = this.generateNext20Years()
    lastValueFrom(combineLatest([
      this.http.get<any>(`${environment.serverUrl}/districts/all`),
      this.http.get<any>(`${environment.serverUrl}/constituencies/all`),
      this.http.get<any>(`${environment.serverUrl}/units/all`),
     ]).pipe(
      map(([districts,constituencies,units]) => { 
        this.districts = districts.data
        this.constituencies = constituencies.data
        this.units = units.data
      })
    ))
    this.statuses = [
      { label: 'Without Unit', value: 'NoUnit' },
      { label: 'Active', value: 'Active' },
      { label: 'Suspended', value: 'Suspended' },
      { label: 'Deleted Not Approved', value: 'SoftDeleted' },
      { label: 'Deleted', value: 'Deleted' },
      { label: 'Deceased Not Approved', value: 'SoftDeceased' },
      { label: 'Deceased', value: 'Deceased' }
    ];

  }

  loadMembers(event: any) {
    this.loading = true;

    // Prepare the parameters for pagination, sorting, and filtering
    const params: any = {
      take: event.rows, // Number of records to take
      skip: event.first, // Starting point
      order: event.sortField || 'createdAt', // Sorting field
      orderDir: event.sortOrder === 1 ? 'ASC' : 'DESC', // Sorting direction
    };

    // Apply filters dynamically if they exist
    if (event.filters.name && event.filters.name.value) {
      params.name = event.filters.name.value;
    }
    if (event.filters.mobileNumber && event.filters.mobileNumber.value) {
      params.mobileNumber = event.filters.mobileNumber.value;
    }
    if (event.filters.membershipNumber && event.filters.membershipNumber.value) {
      params.membershipNumber = event.filters.membershipNumber.value;
    }
    if (event.filters.associationMembershipNumber && event.filters.associationMembershipNumber.value) {
      params.associationMembershipNumber = event.filters.associationMembershipNumber.value;
    }
    if (event.filters.unitName && event.filters.unitName.value) {
      params.units = event.filters.unitName.value.map(a => a.uuid).join(",")
    }
    if (event.filters.natureOfWork && event.filters.natureOfWork.value) {
      params.natureOfWork = event.filters.natureOfWork.value.map(a => a.value).join(",")
    }
    if (event.filters.constituencyName && event.filters.constituencyName.value) {
      params.constituencies = event.filters.constituencyName.value.map(a => a.uuid).join(",")
    }
    if (event.filters.districtName && event.filters.districtName.value) {
      params.districts = event.filters.districtName.value.map(a => a.uuid).join(",")
    }
    if (event.filters.status && event.filters.status.value) {
      params.status = event.filters.status.value;
    }
    if (event.filters.pensionByYear && event.filters.pensionByYear.value) {
      params.pensionByYear = event.filters.pensionByYear.value.value
    }

    const httpParams = new HttpParams({ fromObject: params });

    this.http.get(`${environment.serverUrl}/members`, { params: httpParams }).subscribe(
      (result:any) => {
        this.members = result.data;
        this.members.map (mem =>{
          
        })
        this.totalRecords = result.count;
        
        this.loading = false;
      },
      (error) => {
        console.error('Error fetching members:', error);
        this.loading = false;
      }
    );
  }

  addNewMember(){
    this.router.navigate(['members','add-member'])
  }

  onRowEditInit(member){
    this.router.navigate(['members','edit-member',member.uuid])
  }

  refreshList(){
    if (this.dataTable) {
      this.expandedRows = {}
      this.loadMembers(this.dataTable.createLazyLoadMetadata());
   }
  }
  onRowSelect(event: any) {
    this.onRowExpand(event)
  }

  onRowUnselect(event: any) {
    this.expandedRows = {}
  }
  onRowExpand(event:any) {

    this.expandedRows = {}
    const member = event.data;
    if (member.payments) {
      this.expandedRows[member.id] = true;
      return;
    }

    this.http.get(`${environment.serverUrl}/members/${member.uuid}/payments`).subscribe(
      (result:any) => {
        member.payments = result.data;
        this.expandedRows[member.id] = true;
      },
      (error) => {
        console.error('Error loading payments:', error);
      }
    );
  }

  calculatePensionEligibilityYear(member): number | string {
    if (!member.dateOfBirth || !member.dateJoined || ["Soft Retired","Retired","Soft Deleted","Deleted","Soft Deceased","Deceased"].indexOf(member.status) != -1 ) {
      return "N/A"; // Return undefined if data is missing
    }

    const birthYear = new Date(member.dateOfBirth).getFullYear();
    const joinYear = new Date(member.dateJoined).getFullYear();

    // Year the member turns 60
    const ageEligibilityYear = birthYear + 60;

    // Year the member completes 10 years of membership
    const membershipEligibilityYear = joinYear + 10;

    // The eligibility year is the later of the two
    return Math.max(ageEligibilityYear, membershipEligibilityYear);
  }

  getSeverity(status: string) {

    switch (status) {
      case 'Without Unit':
        return 'warning';
      case 'Active':
        return 'success';
      case 'Suspended':
        return 'info';
      case 'Deleted Not Approved':
        return 'danger';
      case 'Deleted':
        return 'danger';
      case 'Deceased Not Approved':
        return 'danger';
      case 'Deceased':
        return "danger";
    }
}

  getRowBackgroundColor(member){
    if( member.status === 'Active'){
      return '#FFFFFF'
    }
    else if( member.status === 'Suspended'){
      return '#BA8F21'
    }
    else if( member.status === 'No Unit'){
      return '#F4550B'
    }
    else if( member.status === 'Soft-Deleted' || member.status === 'Soft-Deceased'){
      return '#FF0100'
    }
    else if( member.status === 'Deleted' || member.status === 'Deceased'){
      return '#FF0000'
    }
  }
  getRowColor(member){
    if( member.status === 'Active'){
      return '#000'
    }
    else if( member.status === 'Suspended'){
      return '#FF0000'
    }
    else if( member.status === 'No Unit'){
      return '#FF0000'
    }
    else if( member.status === 'Soft-Deleted' || member.status === 'Soft-Deceased'){
      return '#FF0000'
    }
    else if( member.status === 'Deleted' || member.status === 'Deceased'){
      return '#FF0000'
    }
  }

  closeDropdown(){
    console.log("Here")
  }

  getTimestamp(){
    return Date.now()
  }

  generateNext20Years(): number[] {
    const currentYear = new Date().getFullYear();
    const years = [];
  
    for (let i = 0; i < 20; i++) {
      years.push({"name":currentYear + i, "value":currentYear + i});
    }
  
    return years;
  }
}
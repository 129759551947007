<div class="calendar-container">
  <div class="sundays-grid">
    <div (click)="onPaymentSelected(sunday)" class="per-card-3" *ngFor="let sunday of displayedSundays; let i = index;" [ngClass]="{'bg-red': sunday.status === 'UnConfirmed Entries', 'bg-orange' : sunday.status === 'No Payments' || sunday.status === 'Some Unpaid', 'bg-green' : sunday.status === 'All Confirmed'}">
      <div class="card-image">
        <span></span>
        <span class="per-name">{{sunday.paymentDate.format("Do MMM yyyy").toUpperCase()}}</span>
      </div>

      <div class="card-content">
        <span class="per-position" [ngClass]="{'red': sunday.status === 'UnConfirmed Entries', 'orange' : sunday.status === 'No Payments' || sunday.status === 'Some Unpaid', 'green' : sunday.status === 'All Confirmed'}">{{getStatusNameForPayment(sunday)}}</span>
        <div class="card-text">
          <div class="item">
            <span>
              TOTAL DUE
            </span>
            <span style="display: block;padding-left:10px;">
              {{ sunday.totalAmountDue | currency:'INR':'symbol' }}
            </span>
          </div>
          <div style="border-left: 1px solid #F0F0F0;position: absolute;height:50%;top:25%;left: 50%;"></div>
          <div class="item" style="text-align: end;">
            <span>
              TOTAL COLLECTED
            </span>
            <span style="display: block;">
              {{ sunday.totalAmountPaid | currency:'INR':'symbol' }}
            </span>
          </div>
        </div>
        <div class="card-text">
          <div class="item">
            <span>
              # UNPAID MEM.
            </span>
            <span style="display: block;padding-left:10px;" class="red">
              {{ sunday.totalMembersDue - sunday.totalMembersPaid > 0 ? (sunday.totalMembersDue - sunday.totalMembersPaid | number:'2.0') : sunday.totalMembersDue - sunday.totalMembersPaid }}
            </span>
          </div>
          <div style="border-left: 1px solid #F0F0F0;position: absolute;height:50%;top:25%;left: 50%;"></div>
          <div class="item" style="text-align: end;">
            <span>
              # PAID MEM.
            </span>
            <span style="display: block;" class="green">
              {{ sunday.totalMembersPaid > 0 ? (sunday.totalMembersPaid | number:'2.0') : sunday.totalMembersPaid }}
            </span>
          </div>
        </div>
        <div class="card-text">
          <div class="item">
            <span>
              # UNCONFIRMED
            </span>
            <span style="display: block;padding-left:10px;" class="red">
              {{ sunday.totalUnconfirmedTransactions > 0 ? (sunday.totalUnconfirmedTransactions | number:'2.0') : sunday.totalUnconfirmedTransactions }}
            </span>
          </div>
          <div style="border-left: 1px solid #F0F0F0;position: absolute;height:50%;top:25%;left: 50%;"></div>
          <div class="item" style="text-align: end;">
            <span>
              # CONFIRMED
            </span>
            <span style="display: block;" class="green">
              {{ sunday.totalConfirmedTransactions > 0 ? (sunday.totalConfirmedTransactions | number:'2.0') : sunday.totalConfirmedTransactions }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="calendar-header">
      <p-paginator 
    [rows]="pageSize" 
    [totalRecords]="totalRecords" 
    (onPageChange)="onPageChange($event)">
  </p-paginator>
    </div>
  </div>
</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Menu } from '../../services/nav-service.service';
import { FlytAuthGuard } from '../../guards/flyt-auth-guard.guard';
import {Router, RouterModule} from '@angular/router';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports:[
    RouterModule
  ]
})
export class AdminMenuModule { 

  constructor(private router: Router){}
  
  public static MENUITEMS: Menu[] = [
		  {
        title: 'DASHBOARD', icon: 'home', type: 'link', active: true,path: 'dashboard',
        route:{
          path: 'dashboard',
          data: {
            breadcrumb: {
              label: 'Dashboard',
            },
            role: 'Admin, Data Entry',
            animation: 'slideInAnimation'
          },
          children: []
        },
      },
      {
        title: 'MEMBERS', icon: 'file-plus', type: 'sub', active: false, path: 'members',
        route:{
          children: [
            {
              pathMatch: 'full',
              data: {
                title:"All Members",
                breadcrumb: {
                  label: "All Members",
                  info: 'icofont icofont-files'
                },
                role: 'Administrator, Data Entry',
                animation: 'slideInAnimation'
              }
            },
          ]
        },
        children:[
          {
            title: 'All Members', icon: 'file-plus', type: 'link', active: false, path: 'members',
            route:{
              children: [
                {
                  data: {
                    title:"All Dealers",
                    breadcrumb: {
                      label: "All Dealers",
                      info: 'icofont icofont-files'
                    },
                    role: 'Administrator, Data Entry',
                    animation: 'slideInAnimation'
                  }
                }
              ]
            }
          }
        ]
      },
      {
        title: 'PAYMENTS', icon: 'file-plus', type: 'sub', active: false, path: 'members',
        route:{
          children: [
            {
              pathMatch: 'full',
              data: {
                title:"PAYMENTS",
                breadcrumb: {
                  label: "ALL PAYMENTS",
                  info: 'icofont icofont-files'
                },
                role: 'Administrator, Data Entry',
                animation: 'slideInAnimation'
              }
            },
          ]
        },
        children:[
          {
            title: 'WEEKLY PAYMENTS', icon: 'file-plus', type: 'link', active: false, path: 'payments',
            route:{
              children: [
                {
                  data: {
                    title:"ALL PAYMENTS",
                    breadcrumb: {
                      label: "ALL PAYMENTS",
                      info: 'icofont icofont-files'
                    },
                    role: 'Administrator, Data Entry',
                    animation: 'slideInAnimation'
                  }
                }
              ]
            }
          },
          {
            title: 'ALL TRANSACTIONS', icon: 'file-plus', type: 'link', active: false, path: 'payments/all-transactions',
            route:{
              children: [
                {
                  data: {
                    title:"ALL TRANSACTIONS",
                    breadcrumb: {
                      label: "ALL TRANSACTIONS",
                      info: 'icofont icofont-files'
                    },
                    role: 'Administrator, Data Entry',
                    animation: 'slideInAnimation'
                  }
                }
              ]
            }
          },
          {
            title: 'ALL PAYOUTS', icon: 'file-plus', type: 'link', active: false, path: 'payments/all-payouts',
            route:{
              children: [
                {
                  data: {
                    title:"ALL PAYOUTS",
                    breadcrumb: {
                      label: "ALL PAYOUTS",
                      info: 'icofont icofont-files'
                    },
                    role: 'Administrator, Data Entry',
                    animation: 'slideInAnimation'
                  }
                }
              ]
            }
          },
        ]
      },
      {
        title: 'ALL DONATIONS', icon: 'home', type: 'link', active: true,path: 'donations',
        route:{
          path: 'donations',
          data: {
            breadcrumb: {
              label: 'ALL DONATIONS',
            },
            role: 'Admin, Data Entry',
            animation: 'slideInAnimation'
          },
          children: []
        },
      },
      {
        title: 'ALL UNITS', icon: 'home', type: 'link', active: true,path: 'units',
        route:{
          path: 'donations',
          data: {
            breadcrumb: {
              label: 'ALL UNITS',
            },
            role: 'Admin, Data Entry',
            animation: 'slideInAnimation'
          },
          children: []
        },
      },
      {
        title: 'REPORTS', icon: 'file-plus', type: 'sub', active: false, path: 'settings',
        route:{
          children: [
            {
              pathMatch: 'full',
              data: {
                title:"SETTINGS",
                breadcrumb: {
                  label: "SETTINGS",
                  info: 'icofont icofont-files'
                },
                role: 'Administrator, Data Entry',
                animation: 'slideInAnimation'
              }
            },
          ]
        },
        children:[
          {
            title: 'ALL MEMBERS', icon: 'file-plus', type: 'link', active: false, path: 'reports/all-members',
            route:{
              children: [
                {
                  data: {
                    title:"ALL MEMBERS",
                    breadcrumb: {
                      label: "ALL MEMBERS",
                      info: 'icofont icofont-files'
                    },
                    role: 'Administrator, Data Entry',
                    animation: 'slideInAnimation'
                  }
                }
              ]
            }
          },
          {
            title: 'MEMBER CHILDREN', icon: 'file-plus', type: 'link', active: false, path: 'reports/all-school-children',
            route:{
              children: [
                {
                  data: {
                    title:"ALL SCHOOL GOING CHILDREN",
                    breadcrumb: {
                      label: "ALL SCHOOL CHILDREN",
                      info: 'icofont icofont-files'
                    },
                    role: 'Administrator, Data Entry',
                    animation: 'slideInAnimation'
                  }
                }
              ]
            }
          }
        ]
      },
      {
        title: 'SETTINGS', icon: 'file-plus', type: 'sub', active: false, path: 'settings',
        route:{
          children: [
            {
              pathMatch: 'full',
              data: {
                title:"SETTINGS",
                breadcrumb: {
                  label: "SETTINGS",
                  info: 'icofont icofont-files'
                },
                role: 'Administrator, Data Entry',
                animation: 'slideInAnimation'
              }
            },
          ]
        },
        children:[
          {
            title: 'ALL PAYOUT EVENTS', icon: 'file-plus', type: 'link', active: false, path: 'settings/all-payout-events',
            route:{
              children: [
                {
                  data: {
                    title:"ALL PAYOUT EVENTS",
                    breadcrumb: {
                      label: "ALL PAYOUT EVENTS",
                      info: 'icofont icofont-files'
                    },
                    role: 'Administrator, Data Entry',
                    animation: 'slideInAnimation'
                  }
                }
              ]
            }
          },
          {
            title: 'ALL DONATION TYPES', icon: 'file-plus', type: 'link', active: false, path: 'settings/all-donation-types',
            route:{
              children: [
                {
                  data: {
                    title:"ALL DONATION TYPES",
                    breadcrumb: {
                      label: "ALL DONATION TYPES",
                      info: 'icofont icofont-files'
                    },
                    role: 'Administrator, Data Entry',
                    animation: 'slideInAnimation'
                  }
                }
              ]
            }
          },
        ]
      },
	]
}

import { ListSchoolGoingChildrenComponent } from './../../modules/reports/list-school-going-children/list-school-going-children.component';
import { ListAllTransactionsComponent } from './../../modules/payments/list-all-transactions/list-all-transactions.component';
import { ListAllPayoutsComponent } from './../../modules/payments/list-all-payouts/list-all-payouts.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PrivateRoutesRoutingModule } from './private-routes-routing.module';
import { Route, Router, Routes } from '@angular/router';
import { NavService, Menu } from '../../services/nav-service.service';
import { LoginComponent } from '../../auth/login/login.component';
import { ContentLayoutComponent } from '../../shared/components/layout/content-layout/content-layout.component';
import { FlytAuthGuard } from '../../guards/flyt-auth-guard.guard';
import { AuthService } from '../../services/auth-service.service';
import { Error404Component } from '../../pages/error-pages/error404/error404.component';
import { User } from '../../types/types';
import { map, pipe, Subscription, tap } from 'rxjs';
import { DashboardModule } from 'src/app/modules/dashboard/dashboard.module';
import { DefaultComponent } from 'src/app/modules/dashboard/default/default.component';
import { HttpErrorResponse } from '@angular/common/http';
import {RouterModule} from '@angular/router';
import { ListMembersComponent } from '../../modules/member/list-members/list-members.component';
import { AddMemberComponent } from 'src/app/modules/member/add-member/add-member.component';
import { ListAllPaymentsComponent } from 'src/app/modules/payments/list-all-payments/list-all-payments.component';
import { ViewPaymentComponent } from 'src/app/modules/payments/view-payment/view-payment.component';
import { ListAllDonationsComponent } from 'src/app/modules/donation/list-all-donations/list-all-donations.component';
import { ListAllUnitsComponent } from 'src/app/modules/lookup-data/list-all-units/list-all-units.component';
import { ViewUnitComponent } from 'src/app/modules/lookup-data/view-unit/view-unit.component';
import { ListPayoutEventComponent } from 'src/app/modules/lookup-data/list-payout-event/list-payout-event.component';
import { ListUnitDonationTypesComponent } from 'src/app/modules/lookup-data/list-unit-donation-types/list-unit-donation-types.component';
import { ListMembersForReportsComponent } from 'src/app/modules/reports/list-members-for-reports/list-members-for-reports.component';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    PrivateRoutesRoutingModule,
    RouterModule
  ],
  exports:[
    RouterModule
  ]
})
export class PrivateRoutesModule {

  public menuItems: Menu[];

  constructor(private router: Router, public navServices: NavService, private authService: AuthService) {
    const routes: Routes = [
      {
        path: 'auth/login',
        component: LoginComponent
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard'
      },
      {
        path: '',
        component: ContentLayoutComponent,
        pathMatch:'prefix',
        canActivate: [FlytAuthGuard],
        children: [
          {
            title: 'Dashboard', 
            path: 'dashboard',
            canActivate: [FlytAuthGuard],
            component: DefaultComponent,
            data: {
              breadcrumb: {
                label: 'Dashboard',
              },
              active: true,
              type: 'link',
              icon: 'home',
              role: 'Administrator,Data Entry',
              animation: 'slideInAnimation'
            },
          },
          {
            title: 'All Members',
            path: 'members',
            canActivate: [FlytAuthGuard],
            children: [
              {
                path: '',
                pathMatch: 'full',
                canActivate: [FlytAuthGuard],
                component: ListMembersComponent,
                data: {
                  title:"All Members",
                  breadcrumb: {
                    label: "All Members",
                    info: 'icofont icofont-files'
                  },
                  icon: 'home',
                  active: true,
                  type: 'link',
                  role: 'Administrator,Data Entry',
                  animation: 'slideInAnimation'
                }
              },
              {
                path: 'add-member',
                pathMatch: 'full',
                canActivate: [FlytAuthGuard],
                component: AddMemberComponent,
                data: {
                  title:"Add New Member",
                  breadcrumb: {
                    label: "Add New Member",
                    info: 'icofont icofont-files'
                  },
                  icon: 'home',
                  active: true,
                  type: 'link',
                  role: 'Administrator,Data Entry',
                  animation: 'slideInAnimation'
                }
              },
              {
                path: 'edit-member/:uuid',
                pathMatch: 'full',
                canActivate: [FlytAuthGuard],
                component: AddMemberComponent,
                data: {
                  title:"Edit Member",
                  breadcrumb: {
                    label: "Edit Member",
                    info: 'icofont icofont-files'
                  },
                  icon: 'home',
                  active: true,
                  type: 'link',
                  role: 'Administrator,Data Entry',
                  animation: 'slideInAnimation'
                }
              },
            ]
          },
          {
            title: 'All Payments',
            path: 'payments',
            canActivate: [FlytAuthGuard],
            children: [
              {
                path: '',
                pathMatch: 'full',
                canActivate: [FlytAuthGuard],
                component: ListAllPaymentsComponent,
                data: {
                  title:"ALL PAYMENTS",
                  breadcrumb: {
                    label: "ALL PAYMENTS",
                    info: 'icofont icofont-files'
                  },
                  icon: 'home',
                  active: true,
                  type: 'link',
                  role: 'Administrator,Data Entry',
                  animation: 'slideInAnimation'
                }
              },
              {
                path: 'all-transactions',
                pathMatch: 'full',
                canActivate: [FlytAuthGuard],
                component: ListAllTransactionsComponent,
                data: {
                  title:"ALL TRANSACTIONS",
                  breadcrumb: {
                    label: "ALL TRANSACTIONS",
                    info: 'icofont icofont-files'
                  },
                  icon: 'home',
                  active: true,
                  type: 'link',
                  role: 'Administrator,Data Entry',
                  animation: 'slideInAnimation'
                }
              },
              {
                path: 'all-payouts',
                pathMatch: 'full',
                canActivate: [FlytAuthGuard],
                component: ListAllPayoutsComponent,
                data: {
                  title:"ALL PAYOUTS",
                  breadcrumb: {
                    label: "ALL PAYOUTS",
                    info: 'icofont icofont-files'
                  },
                  icon: 'home',
                  active: true,
                  type: 'link',
                  role: 'Administrator,Data Entry',
                  animation: 'slideInAnimation'
                }
              },
              {
                path: 'view-payment/:uuid',
                pathMatch: 'full',
                canActivate: [FlytAuthGuard],
                component: ViewPaymentComponent,
                data: {
                  title:"View Payment",
                  breadcrumb: {
                    label: "Edit Member",
                    info: 'icofont icofont-files'
                  },
                  icon: 'home',
                  active: true,
                  type: 'link',
                  role: 'Administrator,Data Entry',
                  animation: 'slideInAnimation'
                }
              },
            ]
          },
          {
            path: 'donations',
            pathMatch: 'full',
            canActivate: [FlytAuthGuard],
            component: ListAllDonationsComponent,
            data: {
              title:"ALL DONATIONS",
              breadcrumb: {
                label: "ALL DONATIONS",
                info: 'icofont icofont-files'
              },
              icon: 'home',
              active: true,
              type: 'link',
              role: 'Administrator,Data Entry',
              animation: 'slideInAnimation'
            },
          },
          {
            title: 'All Units',
            path: 'units',
            canActivate: [FlytAuthGuard],
            children: [
              {
                path: '',
                pathMatch: 'full',
                canActivate: [FlytAuthGuard],
                component: ListAllUnitsComponent,
                data: {
                  title:"All Units",
                  breadcrumb: {
                    label: "All Members",
                    info: 'icofont icofont-files'
                  },
                  icon: 'home',
                  active: true,
                  type: 'link',
                  role: 'Administrator,Data Entry',
                  animation: 'slideInAnimation'
                }
              },
              {
                path: 'add-unit',
                pathMatch: 'full',
                canActivate: [FlytAuthGuard],
                component: ViewUnitComponent,
                data: {
                  title:"Add New Unit",
                  breadcrumb: {
                    label: "Add New Unit",
                    info: 'icofont icofont-files'
                  },
                  icon: 'home',
                  active: true,
                  type: 'link',
                  role: 'Administrator,Data Entry',
                  animation: 'slideInAnimation'
                }
              },
              {
                path: 'edit-unit/:uuid',
                pathMatch: 'full',
                canActivate: [FlytAuthGuard],
                component: ViewUnitComponent,
                data: {
                  title:"Edit Unit",
                  breadcrumb: {
                    label: "Edit Unit",
                    info: 'icofont icofont-files'
                  },
                  icon: 'home',
                  active: true,
                  type: 'link',
                  role: 'Administrator,Data Entry',
                  animation: 'slideInAnimation'
                }
              },
            ]
          },
          {
            title: 'Reports',
            path: 'reports',
            canActivate: [FlytAuthGuard],
            children: [
              {
                path: 'all-members',
                pathMatch: 'full',
                canActivate: [FlytAuthGuard],
                component: ListMembersForReportsComponent,
                data: {
                  title:"ALL MEMBERS",
                  breadcrumb: {
                    label: "ALL MEMBERS",
                    info: 'icofont icofont-files'
                  },
                  icon: 'home',
                  active: true,
                  type: 'link',
                  role: 'Administrator,Data Entry',
                  animation: 'slideInAnimation'
                }
              },
              {
                path: 'all-school-children',
                pathMatch: 'full',
                canActivate: [FlytAuthGuard],
                component: ListSchoolGoingChildrenComponent,
                data: {
                  title:"ALL SCHOOL GOING CHILDREN",
                  breadcrumb: {
                    label: "ALL SCHOOL GOING CHILDREN",
                    info: 'icofont icofont-files'
                  },
                  icon: 'home',
                  active: true,
                  type: 'link',
                  role: 'Administrator,Data Entry',
                  animation: 'slideInAnimation'
                }
              },
            ]
          },
          {
            title: 'Settings',
            path: 'settings',
            canActivate: [FlytAuthGuard],
            children: [
              {
                path: 'all-payout-events',
                pathMatch: 'full',
                canActivate: [FlytAuthGuard],
                component: ListPayoutEventComponent,
                data: {
                  title:"All Payout Events",
                  breadcrumb: {
                    label: "All Payout Events",
                    info: 'icofont icofont-files'
                  },
                  icon: 'home',
                  active: true,
                  type: 'link',
                  role: 'Administrator,Data Entry',
                  animation: 'slideInAnimation'
                }
              },
              {
                path: 'all-donation-types',
                pathMatch: 'full',
                canActivate: [FlytAuthGuard],
                component: ListUnitDonationTypesComponent,
                data: {
                  title:"All Donation Types",
                  breadcrumb: {
                    label: "All Donation Types",
                    info: 'icofont icofont-files'
                  },
                  icon: 'home',
                  active: true,
                  type: 'link',
                  role: 'Administrator,Data Entry',
                  animation: 'slideInAnimation'
                }
              }
            ]
          },
        ],
        data: {
          role: 'Administrator,Data Entry',
          breadcrumb: {
            label: 'Home',
          }
        }
      },
      {
        path: 'error',
        loadChildren: () => import('../../pages/error-pages/error-pages.module').then(m => m.ErrorPagesModule),
      },
      {
        path: '**',
        component: Error404Component,
      }
    ];      
      this.router.resetConfig(routes); 
  }
}

<p-table
  #dt
  [value]="allDonations"
  [paginator]="true"
  [rows]="10"
  [totalRecords]="totalCount"
  selectionMode="single"
  [lazy]="true"
  [reorderableColumns]="true"
  [(selection)]="selectedPayment" 
  (onRowSelect)="onRowSelect($event)" 
  [rowsPerPageOptions]="[10, 20, 50]"
  (onLazyLoad)="loadData($event)"
  scrollHeight="calc(100vh - 405px)"
  [scrollable]="true" 
  styleClass="mt-3"
  dataKey="id"
>
<ng-template pTemplate="caption">
  <div class="flex flex-wrap justify-content-end gap-2">
      <p-button pRipple class="btn-rounded" label="Refresh Data" icon="pi pi-refresh" text (onClick)="refreshList()" />
  </div>
</ng-template>
  <!-- Expand Button Column -->
  <ng-template pTemplate="header">
    <tr>
      <th pFrozenColumn></th>
      <th pFrozenColumn>Payment Date</th>
      <th style="text-align:right">Donation Paid</th>
      <th>Donation Type</th>
      <th>Unit Name</th>
      <th>Payment Type</th>
      <th>Reference #</th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-donation>
    <tr [pSelectableRow]="donation">
      <td pFrozenColumn></td>
      <td pFrozenColumn>{{ moment(donation.paymentDate).format("Do MMM YYYY") }}</td>
      <td style="text-align:right">{{ getAmountPaid(donation) | currency:'INR':'symbol'}}</td>
      <td >{{ donation.donationType}}</td>
      <td >{{ donation.unit?.name}}</td>
      <td >{{ donation.paymentType }}</td>
      <td>{{ donation.paymentReferenceNumber }}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr  style="height:calc(100vh - 405px)">
        <td colspan="11" style="text-align: center;">There are no member payments</td>
    </tr>
  </ng-template>
</p-table>
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SplitButton } from 'primeng/splitbutton';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth-service.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import Helper from 'src/app/helper/Helper';

@Component({
  selector: 'app-view-payment',
  templateUrl: './view-payment.component.html',
  styleUrls: ['./view-payment.component.scss']
})
export class ViewPaymentComponent implements OnInit {
  @ViewChild('template') imageTemplateRef: TemplateRef<any>;
  
  moment = moment 
  paymentDate
  uuid = ""
  unconfirmed = []
  confirmed = []
  unpaid = []

  viewTransactionDialog = false
  selectedPayment = undefined
  selectedAttachment = undefined

  constructor(private httpClient: HttpClient, private route: ActivatedRoute, private router: Router, private fb: FormBuilder,private toastrService: ToastrService,
    private authService: AuthService, protected modalService: NgbModal) { }

  ngOnInit() {
  }

  ngAfterViewInit(){
    if( this.router.url.split("/")[this.router.url.split("/").length-2] == 'view-payment' ){
      const uuid = this.route.snapshot.params['uuid']
      this.uuid = uuid;
      this.loadPaymentDetails(uuid)
    }
  }
  loadPaymentDetails(uuid: any) {
    
    return lastValueFrom(this.httpClient.get(`${environment.serverUrl}/payments/${uuid}`)).then( (result:any) => {  
      this.paymentDate = result.paymentDate
      this.unpaid = []
      this.unconfirmed = []
      this.confirmed = []
      result.data.map (transaction =>{
        if( transaction.status === "Yet To Pay" ){
          this.unpaid.push(transaction)
        }
        else if( transaction.status === "Paid" ){
          this.unconfirmed.push(transaction)
        }
        else if( transaction.status === "Confirmed" ){
          this.confirmed.push(transaction)
        }
      })
    });
  }
  confirmSelectedPayment(){
    Helper.showConfirmationAlert(`Are you sure?`,`You want to Confirm and Accept this payment?`,`question`,"Yes","No")
    .subscribe((result) => {
      if( result ){
        this.httpClient.post(`${environment.serverUrl}/payments/confirmed`, {paymentUUID:this.selectedPayment.unitPayments?.[0]?.uuid},).subscribe({
            next: (v:any) => {
              Helper.showMessageAlert('Success','Payment successfully confirmed!','success');
              this.viewTransactionDialog = false
              this.loadPaymentDetails(this.uuid)
            },
            error: (e) => {
              console.error(e);
              Helper.processError(e);
            },
            complete: () => console.info('complete')
          });
      }
    })
  }

  declineSelectedPayment(){
    Helper.showConfirmationAlert(`Are you sure?`,`You want to DECLINE this payment? This means you haven't received the payment in your account.`,`question`,"Yes","No")
    .subscribe((result) => {
      if( result ){
        this.httpClient.post(`${environment.serverUrl}/payments/declined`, {paymentUUID:this.selectedPayment.unitPayments?.[0]?.uuid},).subscribe({
          next: (v:any) => {
            Helper.showMessageAlert('Success','Payment successfully declined!','success');
            this.viewTransactionDialog = false
            this.loadPaymentDetails(this.uuid)
          },
          error: (e) => {
            console.error(e);
            Helper.processError(e);
          },
          complete: () => console.info('complete')
        });
      }
    })
  }

  viewTransaction(payment){
    this.selectedPayment = payment
    this.viewTransactionDialog = true
  }

  getImageUrlForAttachment(attachment){
    return `${environment.serverUrl}/file/download?path=${attachment.objectName}`
  }

  openImage(attachment){
    this.selectedAttachment = attachment
    const modalRef = this.modalService.open(this.imageTemplateRef, {backdropClass: 'light-blue-backdrop', centered:true,size:'xl',keyboard:true});
  }
}

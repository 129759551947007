import { state } from '@angular/animations';
import { HttpClient, HttpContext, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, lastValueFrom, map } from 'rxjs';
import Helper from 'src/app/helper/Helper';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { IS_UPLOAD_REQUEST, MultipleFileUpload } from 'src/app/types/types';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth-service.service';
import { MenuItem } from 'primeng/api';
import { SplitButton } from 'primeng/splitbutton';
import { error } from 'console';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver';
declare var require
const Swal = require('sweetalert2')

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.scss']
})
export class AddMemberComponent implements OnInit {

  @ViewChildren('formField', { read: ElementRef }) formFields!: QueryList<ElementRef>;
  @ViewChild('template') imageTemplateRef: TemplateRef<any>;
  
  environment = environment
  moment = moment

  memberForm: FormGroup = undefined; // Define the form group
  member : any = undefined

  natureOfWorks = [
    {
      label : "Contractor", value: "Contractor",
    },
    {
      label : "Mason", value: "Mason",
    },
    {
      label : "Polisher", value: "Polisher",
    },
    {
      label : "Helper", value: "Helper", 
    }
  ]
  bloodGroups = [
    { label: 'A+', value: 'A+' },
    { label: 'A-', value: 'A-' },
    { label: 'B+', value: 'B+' },
    { label: 'B-', value: 'B-' },
    { label: 'O+', value: 'O+' },
    { label: 'O-', value: 'O-' },
    { label: 'AB+', value: 'AB+' },
    { label: 'AB-', value: 'AB-' }
  ];

  qualifications = [
    { label: 'LKG', value: 'LKG' },
    { label: 'UKG', value: 'UKG' },
    { label: 'IST STANDARD', value: '1' },
    { label: 'II ND STANDARD', value: '2' },
    { label: 'III RD STANDARD', value: '3' },
    { label: 'IV TH STANDARD', value: '4' },
    { label: 'V TH STANDARD', value: '5' },
    { label: 'VI TH STANDARD', value: '6' },
    { label: 'VII TH STANDARD', value: '7' },
    { label: 'VIII TH STANDARD', value: '8' },
    { label: 'IX TH STANDARD', value: '9' },
    { label: 'X TH STANDARD', value: '10' },
    { label: 'XI TH STANDARD', value: '11' },
    { label: 'XII TH STANDARD', value: '12' },
    { label: 'OTHERS', value: 'OTHERS' },
  ];

  categories = [
    { label: 'General', value: 'General' },
    { label: 'SC/ST', value: 'SC/ST' },
    { label: 'OBC', value: 'OBC' }
  ];

  paymentTypes = [
    { label: 'Cash', value: 'Cash' },
    { label: 'Bank Transfer', value: 'BankTransfer' },
    { label: 'Credit Card', value: 'Credit Card' },
    { label: 'Debit Card', value: 'Debit Card' },
    { label: 'UPI', value: 'UPI' },
    { label: 'GooglePay', value: 'GooglePay' },
    { label: 'PayTM', value: 'PayTM' },
    { label: 'Debit Card', value: 'Debit Card' },
  ];

  familyMemberRelations = [
    { label: 'Husband', value: 'Husband' },
    { label: 'Wife', value: 'Wife' },
    { label: 'Son', value: 'Son' },
    { label: 'Daughter', value: 'Daughter' },
    { label: 'Father', value: 'Father' },
    { label: 'Mother', value: 'Mother' },
    { label: 'Grand Child', value: 'Grand Child' },
    { label: 'Grand Parent', value: 'Grand Parent' },
    { label: 'Guardian', value: 'Guardian' }
  ]

  memberStatuses = [
    { label: 'Retired', value: 'Retired' },
    { label: 'Delete', value: 'Deleted' },
    { label: 'Deceased', value: 'Deceased' },
  ]

  loggedInUser
  editing: boolean = false
  uuid: string = ""
  societySettings = undefined
  allConstituencies = []
  filteredConstituencies: any[] = [];
  allUnits = []
  filteredUnits = []
  familyMembers = []
  selectedFamilyMember = undefined
  addFamilyMemberDialog = false;
  submittedFamilyMemberData: boolean = false;
  imageSrc = undefined
  selectedImage: File = undefined;
  profileImageAvailable = false
  useImageSrc = false
  memberStatus = undefined
  deceasedStatusDialog = false
  deletedStatusDialog = false
  retiredStatusDialog = false
  loadingStatusData = false
  statusMenuItems: MenuItem[] = []
  deceasedBenefits : any = {}
  retirementBenefits : any = {}
  accidentLastKnowValue = false
  deductionLastKnowValue = 0
  anyOtherDeductionsLastKnowValue = 0
  payoutDetailsBtnEnabled = true
  selectedAttachment
  protected fileUploads : MultipleFileUpload = new MultipleFileUpload()


  constructor(private httpClient: HttpClient, private route: ActivatedRoute, private router: Router, private fb: FormBuilder,private toastrService: ToastrService,
    private authService: AuthService, protected modalService: NgbModal
  ) { }

  ngOnInit() {
    this.authService.getCurrentUser().subscribe({
      next:((user) => {
        this.loggedInUser = user
      })
    })

    lastValueFrom(combineLatest([
      this.httpClient.get<any>(`${environment.serverUrl}/helper/constituencies`),
      this.httpClient.get<any>(`${environment.serverUrl}/units/all`),
      this.httpClient.get<any>(`${environment.serverUrl}/helper/settings`),
     ]).pipe(
      map(([a,b,c]) => { 
        this.allConstituencies = a.data
        this.allUnits = b.data
        this.societySettings = c
      })
    ))
  }

  ngAfterViewInit(){
    if( this.router.url.split("/")[this.router.url.split("/").length-2] == 'edit-member' ){
      this.editing = true
      const uuid = this.route.snapshot.params['uuid']
      this.uuid = uuid;
      this.loadMemberDetails(uuid)
    }
    else{
      this.memberForm = this.fb.group({
        name: ['', Validators.required],
        aadharNumber: ['', Validators.required],
        address: ['', Validators.required],
        dateOfBirth: [undefined],
        dateJoined: [undefined, Validators.required],
        age: new FormControl({value: '', disabled: true}),
        mobileNumber: ['', Validators.required],
        bloodGroup: [''],
        memberCategory: [''],
        district: new FormControl({value: '', disabled: true}),
        constituencyId: [''],
        constituencyName: [''],
        unitId: [''],
        unitName: [''],
        unitNumber: new FormControl({value: '', disabled: true}),
        unitSecretary:new FormControl({value: '', disabled: true}),
        associationMembershipNumber: ['', Validators.required],
        membershipNumber: ['', Validators.required],
        yearsOfMembership: [''],
        experience: [''],
        natureOfWork: [''],
        welfareFundMembershipNumber: [''],
        willingnessToDonateOrgans: new FormControl<string | undefined>(undefined),
        willingnessToDonateBlood: [''],
        willingnessToBePartOfSocialServices: [''],
        married: [''],
        spouseName: [''],
        nomineeName: [''],
        nomineeBankAccountNumber: [''],
        nomineeIfscCode: [''],
        nomineeBranchName: [''],
        nomineesAadharNumber: [''],
        nomineesDateOfBirth: [undefined],
        nomineesWorkNature: [''],
        ownsLand: [''],
        extendOfLand: new FormControl({value: '', disabled: true}),
        ownsHouse: [''],
        houseAreaInSquareFeet: new FormControl({value: '', disabled: true}),
        hasVisionHearingOrOtherIssues: [''],
        detailsOfIllness: new FormControl({value: '', disabled: true}),
        detailsOfOtherIllness: new FormControl({value: '', disabled: true}),
        detailsOfBedRiddenFamilyMembers: new FormControl({value: '', disabled: true}),
        detailsOfFamilyMembersUnderMentalStress: new FormControl({value: '', disabled: true}),
        hasBedriddenFamilyMembers: [0],
        hasFamilyMembersUnderMentalStress: [0],
        hasFamilyMembersWithCancerOrTerminalIllness: [''],
        hasChildrenExcelledInArtsOrSports: [0],
        detailsOfArtsOrSports: [''],
        joiningFeeAmount: [''],
        joiningFeePaymentType: [''],
        grade: [''],
        upiId:new FormControl({value: '', disabled: false}),
        bankAccountNumber: new FormControl({value: '', disabled: false}),
        ifscCode: new FormControl({value: '', disabled: false}),
        branchName: new FormControl({value: '', disabled: false}),
        familyMembers: []
      });
    }
  }

  loadMemberDetails(uuid: any) {
    return lastValueFrom(this.httpClient.get(`${environment.serverUrl}/members/${uuid}`)).then( (result:any) => {  
      this.member = result
      this.familyMembers = this.member.familyMembers
      this.updateStatusDropdown()
      this.memberForm = this.fb.group({
        name: [this.member.name, Validators.required],
        aadharNumber: [this.member.aadharNumber, Validators.required],
        address: [this.member.address, Validators.required],
        dateOfBirth: [this.member.dateOfBirth ? new Date(this.member.dateOfBirth): undefined],
        dateJoined: [this.member.dateJoined ? new Date(this.member.dateJoined): undefined, Validators.required],
        age: new FormControl({value: '', disabled: true}),
        mobileNumber: [this.member.mobileNumber, Validators.required],
        bloodGroup: [this.member.bloodGroup],
        memberCategory: [this.member.memberCategory],
        district: new FormControl({value: this.member.constituency?.district.name, disabled: true}),
        constituencyId: [this.member.constituency?.id],
        constituencyName: [this.member.constituency?.name],
        unitId: [this.member.unit?.id],
        unitName: [this.member.unit?.name],
        unitNumber: new FormControl({value: this.member.unit?.unitNumber, disabled: true}),
        unitSecretary:new FormControl({value: this.member.unit?.secretary?.name, disabled: true}),
        associationMembershipNumber: [this.member.associationMembershipNumber, Validators.required],
        membershipNumber: [this.member.membershipNumber, Validators.required],
        yearsOfMembership: [this.member.yearsOfMembership],
        experience: [this.member.experience],
        natureOfWork: [this.member.natureOfWork],
        welfareFundMembershipNumber: [this.member.welfareFundMembershipNumber],
        willingnessToDonateOrgans: [this.member.willingnessToDonateOrgans],
        willingnessToDonateBlood: [this.member.willingnessToDonateBlood],
        willingnessToBePartOfSocialServices: [this.member.willingnessToBePartOfSocialServices],
        married: [this.member.married],
        spouseName: [this.member.spouseName],
        nomineeName: [this.member.nomineeName],
        nomineeBankAccountNumber: [this.member.nomineeBankAccountNumber],
        nomineeIfscCode: [this.member.nomineeIfscCode],
        nomineeBranchName: [this.member.nomineeBranchName],
        nomineesAadharNumber: [this.member.nomineesAadharNumber],
        nomineesDateOfBirth: [this.member.nomineesDateOfBirth ? new Date(this.member.nomineesDateOfBirth): undefined],
        nomineesWorkNature: [this.member.nomineesWorkNature],
        ownsLand: [this.member.ownsLand],
        extendOfLand: new FormControl({value: this.member.extendOfLand, disabled: true}),
        ownsHouse: [this.member.ownsHouse],
        houseAreaInSquareFeet: new FormControl({value: this.member.houseAreaInSquareFeet, disabled: true}),
        hasVisionHearingOrOtherIssues: [this.member.hasVisionHearingOrOtherIssues],
        detailsOfIllness: new FormControl({value: this.member.detailsOfIllness, disabled: true}),
        detailsOfOtherIllness: new FormControl({value: this.member.detailsOfOtherIllness, disabled: true}),
        detailsOfBedRiddenFamilyMembers: new FormControl({value: this.member.detailsOfBedRiddenFamilyMembers, disabled: true}),
        detailsOfFamilyMembersUnderMentalStress: new FormControl({value: this.member.detailsOfFamilyMembersUnderMentalStress, disabled: true}),
        hasBedriddenFamilyMembers: [this.member.hasBedriddenFamilyMembers],
        hasFamilyMembersUnderMentalStress: [this.member.hasFamilyMembersUnderMentalStress],
        hasFamilyMembersWithCancerOrTerminalIllness: [this.member.hasFamilyMembersWithCancerOrTerminalIllness],
        hasChildrenExcelledInArtsOrSports: [this.member.hasChildrenExcelledInArtsOrSports],
        detailsOfArtsOrSports: [this.member.detailsOfArtsOrSports],
        joiningFeeAmount: [this.member.joiningFeeAmount],
        joiningFeePaymentType: [this.member.joiningFeePaymentType],
        grade: [this.member.grade],
        upiId:new FormControl({value: this.member.upiId, disabled: false}),
        bankAccountNumber: new FormControl({value: this.member.bankAccountNumber, disabled: false}),
        ifscCode: new FormControl({value: this.member.ifscCode, disabled: false}),
        branchName: new FormControl({value: this.member.branchName, disabled: false}),
        familyMembers: []
      });
      if( this.member.profileImage ){
        this.profileImageAvailable = true
      }
      this.memberStatus = this.member.status
      this.onChangeOtherIllness()
      this.onSelectDateOfBirth(this.member.dateOfBirth)
      this.onChangeOwnsLand({checked: this.member.ownsLand})
      this.onChangeOwnsHouse({checked: this.member.ownsHouse})
      this.onChangeVisionHearingImpaired({checked: this.member.hasVisionHearingOrOtherIssues})
      this.onChangeBedRiddenFamilyMembers({checked: this.member.hasBedriddenFamilyMembers})
      this.onChangeFamilyMembersUndermentalStress({checked: this.member.hasFamilyMembersUnderMentalStress})

    })
    .catch(e => {
      Helper.processError(e)
      this.router.navigate(['members'])
    });
  }

  save() {
    
    if (!this.memberForm.valid) {
      this.memberForm.markAllAsTouched();
      this.focusFirstInvalidControl()
      Helper.showMessageAlert('Error',`Please fill in all the mandatory fields before saving.`,'error');
      return;
    }

    let postUrl = `${environment.serverUrl}/members/`
    if( this.member && this.member?.id){
      postUrl = `${environment.serverUrl}/members/${this.member?.uuid}`
    }

    const formData: FormData = new FormData();    
    for (const field in this.memberForm.controls) { 
      const control = this.memberForm.get(field);
      if( control.value && control.value != null)
        formData.append(field,control.value)
    }

    if( this.selectedImage)
      formData.append("profileImage",this.selectedImage);

    if( this.fileUploads && this.fileUploads.attachments.length > 0 ){
      this.fileUploads.attachments.forEach((file) => { formData.append('applicationForms', file); });
    }

    if( this.familyMembers && this.familyMembers.length > 0 ){
      formData.append("familyMembers",JSON.stringify(this.familyMembers))
    }
    if( !this.profileImageAvailable ){
      formData.append("profileImageRemoved","true");
    }

    this.httpClient.post(postUrl, formData,{
      context:new HttpContext().set(IS_UPLOAD_REQUEST, true)
      }).subscribe({
        next: (v:any) => {
          console.log(v)
          Helper.showMessageAlert('Success','Member added successfully!','success');
          this.router.navigate(['members'])
        },
        error: (e) => {
          console.error(e);
          Helper.processError(e);
        },
        complete: () => console.info('complete')
      });
  }

  addNewFamilyMember(){
    this.resetFamilyMemberData()
    this.submittedFamilyMemberData = false;
    this.addFamilyMemberDialog = true
  }

  editFamilyMember(familyMember){
    this.selectedFamilyMember = { ...familyMember };
    this.submittedFamilyMemberData = false
    this.addFamilyMemberDialog = true;
  }

  deleteFamilyMember(familyMember){
    Helper.showConfirmationAlert(`Are you sure?`,`You want to remove the selected family member?`,`question`,"Yes","No")
    .subscribe((result) => {
      if( result ){
        this.familyMembers = this.familyMembers.filter((val) => val.id !== familyMember.id);
        this.resetFamilyMemberData()
      }
    })
  }

  saveFamilyMember(){
    this.submittedFamilyMemberData = true;

    if (this.selectedFamilyMember.name?.trim()) {
      if (this.selectedFamilyMember.id) {
        this.familyMembers[this.findIndexById(this.selectedFamilyMember.id)] = this.selectedFamilyMember;
    } else {
        this.selectedFamilyMember.dummyId = this.createId();
        this.familyMembers.push(this.selectedFamilyMember);
      }
      this.addFamilyMemberDialog = false;
    }
  }

  printMemberPDF() {
    this.httpClient.post(`${environment.serverUrl}/members/${this.member.uuid}/print`, {}, {
        responseType: 'blob', // Correctly set the response type here
        headers: new HttpHeaders({ 'Accept': 'application/pdf' })
    })
    .subscribe({
        next: (blob: Blob) => {
            saveAs(blob, `${this.member.name}.pdf`); // Triggers the "Save As" dialog
        },
        error: (error: HttpErrorResponse) => {
            console.error('Download failed:', error);
        }
    });
}

  markAsDeceased(hard: boolean) {

    if( this.member.status === "Suspended" && this.member.totalDues > 280){
      Helper.showMessageAlert('Error',`Status of ${this.member.name} is "Temporarily Inactive". They have to pay their dues before they can be marked as deceased from the society.`,'error');
      return
    }

    Helper.showConfirmationAlert(`Are you sure?`,`You want change the status of this member to Deceased?`,`question`,"Yes","No")
    .subscribe((result) => {
      if( result ){
        this.deceasedBenefits = {
          name : this.member.name,
          deductionInPercentage: 0,
          anyOtherDeductions:0,
          dateJoined : this.member.dateJoined ? new Date(this.member.dateJoined): undefined,
          experience: this.member.dateJoined ? Math.floor(moment().diff(moment(this.member.dateJoined), 'years', true)) : "-",
          accident: false,
          hardDeceased: hard
        }
        this.deceasedStatusDialog = true
      }
    })
  }
  markAsDeleted(hard: boolean) {
    Helper.showConfirmationAlert(`Are you sure?`,`You want change the status of this member to Deleted?`,`question`,"Yes","No")
    .subscribe((result) => {
      if( result ){
        this.retirementBenefits = {
          name : this.member.name,
          deductionInPercentage: 0,
          anyOtherDeductions:0,
          dateJoined : this.member.dateJoined ? new Date(this.member.dateJoined): undefined,
          experience: this.member.dateJoined ? Math.floor(moment().diff(moment(this.member.dateJoined), 'years', true)) : "-",
          retired: false
        }
        this.deletedStatusDialog = true
      }
    })
  }
  markAsRetired(hard: boolean) {

    if( this.member.status === "Suspended"){
      Helper.showMessageAlert('Error',`Status of ${this.member.name} is "Suspended". They have to pay their dues before they can retire from the society.`,'error');
      return
    }

    if(!this.member.dateJoined){
      Helper.showMessageAlert('Error',`Date Joined for the member ${this.member.name} is empty. Please save the joined date before proceeding.`,'error');
      return
    }
    const yearsInSociety = Math.floor(moment().diff(moment(this.member.dateJoined), 'years', true))
    if( Math.floor(moment().diff(moment(this.member.dateJoined), 'years', true)) < this.societySettings.minimumYearsRequiredForRetirement ){
      Helper.showMessageAlert('Error',`${this.member.name} has been a member in the society for only ${yearsInSociety} years. To be elligible for retirement, they have to be member for atleast ${this.societySettings.minimumYearsRequiredForRetirement} years.`,'error');
      return;
    }
    Helper.showConfirmationAlert(`Are you sure?`,`You want change the status of this member to Retired?`,`question`,"Yes","No")
    .subscribe((result) => {
      if( result ){
        this.retirementBenefits = {
          name : this.member.name,
          deductionInPercentage: 0,
          anyOtherDeductions:0,
          dateJoined : this.member.dateJoined ? new Date(this.member.dateJoined): undefined,
          experience: this.member.dateJoined ? Math.floor(moment().diff(moment(this.member.dateJoined), 'years', true)) : "-",
          retired: true
        }
        this.deletedStatusDialog = true
      }
    })
  }

  getDeceasedPayoutDetails(){
    this.accidentLastKnowValue = this.deceasedBenefits.accident
    this.deductionLastKnowValue = this.deceasedBenefits.deductionInPercentage
    this.anyOtherDeductionsLastKnowValue = this.deceasedBenefits.anyOtherDeductions
    this.loadingStatusData = true
    return lastValueFrom(this.httpClient.post(`${environment.serverUrl}/members/status/deceased-benefits/${this.member.uuid}`,{accident:this.deceasedBenefits.accident,deductionInPercentage:this.deceasedBenefits.deductionInPercentage, anyOtherDeductions:this.deceasedBenefits.anyOtherDeductions})).then( (result:any) => {  
      this.loadingStatusData = false
      this.payoutDetailsBtnEnabled = false
      this.deceasedBenefits = { ...this.deceasedBenefits, ...result.benefits}
    })
    .catch(e => {
      this.loadingStatusData = false
      Helper.processError(e)
    });
  }

  getExpelledPayoutDetails(){
    this.deductionLastKnowValue = this.retirementBenefits.deductionInPercentage
    this.anyOtherDeductionsLastKnowValue = this.retirementBenefits.anyOtherDeductions
    this.loadingStatusData = true
    return lastValueFrom(this.httpClient.post(`${environment.serverUrl}/members/status/retirement-benefits/${this.member.uuid}`,{retired: this.retirementBenefits.retired, deductionInPercentage:this.retirementBenefits.deductionInPercentage, anyOtherDeductions:this.retirementBenefits.anyOtherDeductions})).then( (result:any) => {  
      this.loadingStatusData = false
      this.payoutDetailsBtnEnabled = false
      this.retirementBenefits = { ...this.retirementBenefits, ...result.benefits}
    })
    .catch(e => {
      this.loadingStatusData = false
      Helper.processError(e)
    });
  }

  changeStatusToDeceased(){
    if( !this.deceasedBenefits.paymentType || this.deceasedBenefits.paymentType.length == 0 ){
      return;
    }
    this.loadingStatusData = true
    return lastValueFrom(this.httpClient.post(`${environment.serverUrl}/members/status/deceased/${this.member.uuid}`,{accident:this.accidentLastKnowValue,deductionInPercentage:this.deductionLastKnowValue,anyOtherDeductions:this.anyOtherDeductionsLastKnowValue, paymentReferenceNumber: this.deceasedBenefits.paymentReferenceNumber, paymentType: this.deceasedBenefits.paymentType, status : this.loggedInUser.role === "Administrator" ? "Deceased" : "SoftDeceased" })).then( (result:any) => {  
      this.member = result.member
      this.updateStatusDropdown()
      this.loadingStatusData = false
      this.deceasedStatusDialog = false
      Helper.showMessageAlert('Success','Member status updated successfully!','success');
    })
    .catch(e => {
      this.loadingStatusData = false
      Helper.processError(e)
    });
  }

  changeStatusToExpelled(){
    if( !this.retirementBenefits.paymentType || this.retirementBenefits.paymentType.length == 0 ){
      return;
    }
    this.loadingStatusData = true
    let status = ""
    if( !this.retirementBenefits.retired ){
      if( this.loggedInUser.role === "Administrator" ){
        status = "Deleted"
      }
      else{
        status = "SoftDeleted"
      }
    }
    else{
      if( this.loggedInUser.role === "Administrator" ){
        status = "Retired"
      }
      else{
        status = "SoftRetired"
      }
    }
    return lastValueFrom(this.httpClient.post(`${environment.serverUrl}/members/status/deleted/${this.member.uuid}`,{retired: this.retirementBenefits.retired,deductionInPercentage:this.deductionLastKnowValue,anyOtherDeductions:this.anyOtherDeductionsLastKnowValue, paymentReferenceNumber: this.retirementBenefits.paymentReferenceNumber, paymentType: this.retirementBenefits.paymentType, status : status })).then( (result:any) => {  
      this.member = result.member
      this.updateStatusDropdown()
      this.loadingStatusData = false
      this.deletedStatusDialog = false
      Helper.showMessageAlert('Success','Member status updated successfully!','success');
    })
    .catch(e => {
      this.loadingStatusData = false
      Helper.processError(e)
    });
  }

  confirmStatusUpdate(){
    Helper.showConfirmationAlert(`Are you sure?`,`You want to confirm the status change?`,`question`,"Yes","No")
    .subscribe((result) => {
      if( result ){
        let status = ""
        if( this.member.status === "Soft Deleted"){
          status = "Deleted"
        }
        else if( this.member.status === "Soft Deceased"){
          status = "Deceased"
        }
        else if( this.member.status === "Soft Retired"){
          status = "Retired"
        }
        return lastValueFrom(this.httpClient.post(`${environment.serverUrl}/members/status/update/${this.member.uuid}`,{status:status})).then( (result:any) => {  
          this.member = result.member
          Helper.showMessageAlert('Success','Member status updated successfully!','success');
        })
        .catch(e => {
          this.loadingStatusData = false
          Helper.processError(e)
        });
      }
    })
  }

  showConfirmButton(){
    if( this.loggedInUser.role === "Administrator"){
      if( this.member?.status === "Soft Deleted" || this.member?.status === "Soft Retired" || this.member?.status === "Soft Deceased" ){
        return true
      }
    }
    return false
  }

  onConstituencySelected(e){
    this.memberForm.patchValue({
      constituencyId:e.value.id,
      constituencyName:e.value.name,
      district: e.value.district?.name, 
    });
  }
  filterConstituency(event) {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.allConstituencies.length; i++) {
      let constituency = this.allConstituencies[i];
      if (constituency.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(constituency);
      }
    }

    this.filteredConstituencies = filtered;
  }

  onUnitSelected(e){
    this.memberForm.patchValue({
      unitId: e.value.id, 
      unitName: e.value.name, 
      unitNumber:e.value.unitNumber,
      unitSecretary: e.value.secretary?.name, 
    });
  }
  filterUnits(event) {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.allUnits.length; i++) {
      let unit = this.allUnits[i];
      if (unit.name.toLowerCase().includes(query.toLowerCase())) {
        filtered.push(unit);
      }
    }

    this.filteredUnits = filtered;
  }

  onSelectDateOfBirth(e){
    this.memberForm.patchValue({
      age: e ? moment().diff(e, 'years') : ''
    });
  }

  onChangeVisionHearingImpaired(e){
    if( e.checked ){
      this.memberForm.get('detailsOfIllness').enable();
    }
    else{
      this.memberForm.get('detailsOfIllness').disable();
    }
  }
  onChangeBedRiddenFamilyMembers(e){
    if( e.checked ){
      this.memberForm.get('detailsOfBedRiddenFamilyMembers').enable();
    }
    else{
      this.memberForm.get('detailsOfBedRiddenFamilyMembers').disable();
    }
  }
  onChangeFamilyMembersUndermentalStress(e){
    if( e.checked ){
      this.memberForm.get('detailsOfFamilyMembersUnderMentalStress').enable();
    }
    else{
      this.memberForm.get('detailsOfFamilyMembersUnderMentalStress').disable();
    }
  }

  onChangeOtherIllness(){
    if( this.memberForm.get('hasFamilyMembersWithCancerOrTerminalIllness').value ){
      this.memberForm.get('detailsOfOtherIllness').enable();
    }
    else{
      this.memberForm.get('detailsOfOtherIllness').disable();
    }
  }

  onChangeOwnsLand(e){
    if( e.checked ){
      this.memberForm.get('extendOfLand').enable();
    }
    else{
      this.memberForm.get('extendOfLand').disable();
    }
  }

  onChangeOwnsHouse(e){
    if( e.checked ){
      this.memberForm.get('houseAreaInSquareFeet').enable();
    }
    else{
      this.memberForm.get('houseAreaInSquareFeet').disable();
    }
  }

  hideDialog(){
    this.submittedFamilyMemberData = false;
    this.addFamilyMemberDialog = false
  }
  
  resetFamilyMemberData(){
    this.selectedFamilyMember = {
      name: '',
      age: '',
      relation: '',
      educationalQualification: ''
    };
  }

  updateStatusDropdown() {
    if( !this.editing || this.member.status === "Retired" || this.member.status === "Deleted" || this.member.status === "Deceased"
      || this.member.status === "Soft Retired" || this.member.status === "Soft Deleted" || this.member.status === "Soft Deceased"
    ){
      this.statusMenuItems = []
      return
    }
    if( this.loggedInUser.role === "Administrator"){
      this.statusMenuItems = this.getAdministratorStatusMenu()
    }
    else{
      this.statusMenuItems = this.getNormalUserStatusMenu()
    }
  }

  getAdministratorStatusMenu(){
    return [
      {
          label: 'To Retired',
          command: () => {
              this.markAsRetired(true)
          }
      },
      { separator: true },
      {
        label: 'To Expelled',
        command: () => {
            this.markAsDeleted(true)
        }
      },
      { separator: true },
      {
        label: 'To Deceased',
          command: () => {
            this.markAsDeceased(true)
          }
      },
    ];
  }

  getNormalUserStatusMenu(){
    return [
      {
          label: 'To Retired',
          command: () => {
              this.markAsRetired(false)
          }
      },
      { separator: true },
      {
        label: 'To Expelled',
        command: () => {
            this.markAsDeleted(false)
        }
      },
      { separator: true },
      {
        label: 'To Deceased',
          command: () => {
            this.markAsDeceased(false)
          }
      },
    ];
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.familyMembers.length; i++) {
        if (this.familyMembers[i].id === id) {
            index = i;
            break;
        }
    }

    return index;
  }

  createId(): string {
    let id = '';
    var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return id;
  }
  removeServerImage(attachment){
    Swal.fire({
      title: 'Are you sure you want to delete the attachment?',
      text: "Remember: This action cannot be reversed,",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#ff7c92',
      confirmButtonText: 'Yes',
      cancelButtonText:'No'
    }).then((result) => {
      if( result.value ){
        let params = {"path":attachment.objectName}
        this.httpClient.delete(`${environment.serverUrl}/members/${this.member.uuid}/application-forms?attachmentId=${attachment.uuid}`)
        .subscribe({
          next: (result) =>{
            this.member.applicationForms = this.member.applicationForms.filter( object => object.id !== attachment.id )
          },
          error:(err) =>{
            Helper.processError(err)
          }
        })
      }
    })
  }

  async addImage(event){
    if (event.target.files.length === 0)
      return;
    this.selectedImage = event.target.files[0];
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == undefined) {
      return;
    }
    this.imageSrc = await this.toBase64(event.target.files[0]);
    this.profileImageAvailable = true
    this.useImageSrc = true
  }

  removeImage(){
    this.imageSrc = undefined;
    this.selectedImage = undefined
    this.useImageSrc = false
    this.profileImageAvailable = false
  }

  removeAttachmentImage(i){
    this.fileUploads.attachments.splice(i,1)
    this.fileUploads.progressInfos.splice(i,1)
    this.fileUploads.previews.splice(i,1)
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  async addDocuments(event){
    if (event.target.files.length === 0)
      return;
    
    for (var i = 0; i <= event.target.files.length - 1; i++) {
      
      const reader = new FileReader();
      var that = this

      reader.onload = (function(file){
        return function(e){
          that.fileUploads.attachments.push(file);
          that.fileUploads.progressInfos.push( { value: 0, fileName: file.name,size:Helper.humanFileSize(file.size), type:'' } );
          that.fileUploads.previews.push(e.target.result);
        }
      })(event.target.files[i])

      reader.readAsDataURL(event.target.files[i]);
    }
  }

  getColorForStatus(){
    if( this.member.status == 'Suspended' || this.member.status == 'No Unit' || this.member.status == 'Retired' 
      || this.member.status == 'Deleted' || this.member.status == 'Deceased'
     )
     {
        return 'red'
     }
     else if( this.member.status == 'Soft Deleted' || this.member.status == 'Soft Deceased' ){
        return 'orange'
     }
     else if( this.member.status == 'Active' || this.member.status == 'Member Created' ){
        return 'green'
     }
  }

  spltBtnClick(event: SplitButton, eventClick: MouseEvent): void {
    setTimeout(() => {
      event.onDropdownButtonClick(eventClick)
    }, 0);
  }

  getImageUrlForAttachment(attachment){
    return `${environment.serverUrl}/file/download?path=${attachment.objectName}`
  }

  openImage(attachment){
    this.selectedAttachment = attachment
    const modalRef = this.modalService.open(this.imageTemplateRef, {backdropClass: 'light-blue-backdrop', centered:true,size:'lg',keyboard:true});
  }

  downloadAttachment(attachment){
    this.httpClient.get(this.getImageUrlForAttachment(attachment), { responseType: 'blob' })
    .subscribe({
      next: (blob) => {
        saveAs(blob, attachment.originalFileName); // Triggers the "Save As" dialog
      },
      error: (error: HttpErrorResponse) => {
        console.error('Download failed:', error);
      }
    } )
  }

  private focusFirstInvalidControl() {
    const formControls = this.memberForm.controls;

    // Iterate through the controls using Object.keys
    for (const key of Object.keys(formControls)) {
      const control = this.memberForm.get(key);

      // If the control is invalid, find the corresponding input element and focus it
      if (control?.invalid) {
        const invalidControl = this.formFields.find((element) => {
          const inputElement = element.nativeElement.querySelector('input');
          // Check if the input element's name matches the key or fallback to nativeElement.name
          return inputElement?.name === key || element.nativeElement.name === key;
        });

        if (invalidControl) {
          const inputElement = invalidControl.nativeElement.querySelector('input');

          // Focus the input element if it exists, otherwise fallback to the native element
          if (inputElement) {
            inputElement.focus();
          } else {
            invalidControl.nativeElement.focus();
          }
        }
        break; // Focus only on the first invalid control
      }
    }
  }

  getTimestamp(){
    return Date.now()
  }

  getQualification(qualification){
    switch(qualification){
      case 'LKG': return "LKG"
      case 'UKG': return "UKG"
      case '1': return "IST STANDARD"
      case '2': return "II ND STANDARD"
      case '3': return "III RD STANDARD"
      case '4': return "IV TH STANDARD"
      case '5': return "V TH STANDARD"
      case '6': return "VI TH STANDARD"
      case '7': return "VII TH STANDARD"
      case '8': return "VIII TH STANDARD"
      case '9': return "IX TH STANDARD"
      case '10': return "X TH STANDARD"
      case '11': return "XI TH STANDARD"
      case '12': return "XII TH STANDARD"
      case 'OTHERS': return "OTHERS"

    }
  }
}
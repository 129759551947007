<p-table
  #dt
  [value]="allPayouts"
  [paginator]="true"
  [rows]="10"
  [totalRecords]="totalRecords"
  selectionMode="single"
  [(selection)]="selectedPayout" 
  [lazy]="true"
  [reorderableColumns]="true"
  [loading]="loading"
  [rowsPerPageOptions]="[10, 20, 50]"
  (onRowSelect)="onRowSelect($event)" 
  (onLazyLoad)="loadTransactions($event)"
  scrollHeight="calc(100vh - 405px)"
  [scrollable]="true" 
  styleClass="mt-3"
  dataKey="id"
>
<ng-template pTemplate="caption">
  <div class="flex flex-wrap justify-content-end gap-2">
      <p-button pRipple class="btn-rounded" label="Refresh Data" icon="pi pi-refresh" text (onClick)="refreshList()" />
      <p-button pRipple class="btn-rounded p-button-success" severity="success" label="New Payout" icon="pi pi-plus" text (onClick)="addNewPayout()" />
  </div>
</ng-template>
  <!-- Expand Button Column -->
  <ng-template pTemplate="header">
    <tr>
      <th pFrozenColumn></th>
      <th pFrozenColumn pSortableColumn="member.name">Requested Member <p-sortIcon field="member.name" /></th>
      <th pFrozenColumn pSortableColumn="member.unit.name">Unit <p-sortIcon field="member.unit.name" /></th>
      <th pFrozenColumn pSortableColumn="payoutEvent.name">Cause <p-sortIcon field="payoutEvent.name" /></th>
      <th pFrozenColumn pSortableColumn="requestedDate">Requested Date<p-sortIcon field="requestedDate" /></th>
      <th style="text-align:right">Total Amount Paid</th>
      <th>Status</th>
      <th pFrozenColumn pSortableColumn="paymentDate">Payment Date<p-sortIcon field="paymentDate" /></th>
      <th style="text-align:left">Requested By</th>
      <th style="text-align:left">Approved By</th>
      <th style="text-align:left">Declined By</th>
      <th></th>
    </tr>
    <tr>
      <th></th>
      <th>
        <p-columnFilter 
          matchMode="in"
          [showMenu]="false"
          type="text" 
          field="name" 
          placeholder="Search by name" 
          ariaLabel="Filter Name" />
      </th>
      <th>
        <p-columnFilter *ngIf="units && units.length > 0" field="unitName" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect 
                  appendTo="body"
                  [ngModel]="value" 
                  [options]="units" 
                  placeholder="Any" 
                  (onChange)="filter($event.value)" 
                  optionLabel="name"
                  >
                      <ng-template let-option pTemplate="item">
                          <div class="inline-block vertical-align-middle">
                              <span class="ml-1 mt-1">{{ option.name }}</span>
                          </div>
                      </ng-template>
              </p-multiSelect>
          </ng-template>
        </p-columnFilter>
      </th>
      <th></th>
      <th></th>
      <th></th>
      <th>
        <p-columnFilter field="status" matchMode="equals" [showMenu]="false" >
        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-dropdown 
                [ngModel]="value" 
                [options]="statuses"
                (onChange)="filter($event.value)" 
                placeholder="Select One" 
                appendTo="body"
                [showClear]="true">
                    <ng-template let-option pTemplate="item">
                        <p-tag 
                            [value]="option.label" 
                            [severity]="option.value" />
                    </ng-template>
            </p-dropdown>
        </ng-template>
      </p-columnFilter>
    </th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-payout>
    <tr [pSelectableRow]="payout">
      <td pFrozenColumn></td>
      <td pFrozenColumn>{{ payout.member?.name }}</td>
      <td pFrozenColumn>{{ payout.member?.unit?.name }}</td>
      <td pFrozenColumn>{{ payout.payoutEvent?.name }}</td>
      <td pFrozenColumn>{{ payout.requestedDate ? moment(payout.requestedDate).format("Do MMM YYYY") : "N/A"}}</td>
      <td style="text-align:right">{{ payout.amountPaid | currency:'INR':'symbol'}}</td>
      <td [ngStyle]="{'background-color' : getBackgroundColor(payout), 'color':getColor(payout)}">{{ payout.status.toUpperCase() }}</td>
      <td>{{ payout.paymentDate ? moment(payout.paymentDate).format("Do MMM YYYY") : "N/A"}}</td>
      <td >{{ payout.requestedBy?.name }}</td>
      <td>{{ payout.approvedBy?.name }}</td>
      <td>{{ payout.declinedBy?.name }}</td>
      <td>
        <div class="flex align-items-center justify-content-center gap-2">
          <!-- <button style="background-color: white !important;color:#000" pButton [raised]="true" (click)="viewTransaction(payment)" class="btn btn-primary m-r-5">VIEW</button> -->
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr  style="height:calc(100vh - 405px)">
        <td colspan="11" style="text-align: center;">There are no payouts matching your search criteria</td>
    </tr>
  </ng-template>
</p-table>

<p-dialog 
autoZIndex="false"
  baseZIndex ="1000"
  [(visible)]="viewTransactionDialog" 
  [style]="{ width: '900px'}" 
  [header]="'Payout Details'" 
  (onHide)="viewTransactionDialog=false;"
  [modal]="true" 
  styleClass="p-fluid">
      <ng-template pTemplate="content">
        <div *ngIf="loadingStatusData" style="width:100%;height:400px;background-color: #fff;justify-content: center;align-items: center;display: flex;">
          <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" ariaLabel="loading" />
        </div>
        <form [formGroup]="payoutForm" (ngSubmit)="save()">
          <div class="view-transaction">
            <div class="vt-container">
              <div class="row">
                <div class="col-md-12 mb-3">
                  <label for="member">MEMBER NAME</label><span class="text-danger">*</span>
                  <p-autoComplete  #formField id="member" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" formControlName="member" [suggestions]="filteredMembers" (onSelect)="onMemberSelected($event)" (completeMethod)="filterMembers($event)" field="name" [dropdown]="false" class="form-control-prime" [readonly]="editing" appendTo="body">
                    <ng-template let-member pTemplate="item">
                        <div class="member-item">
                        <div>{{member.name}}</div>
                        </div>
                    </ng-template>
                  </p-autoComplete>
                  <div *ngIf="payoutForm.controls.memberSelected.invalid && (payoutForm.controls.memberSelected.dirty || payoutForm.controls.memberSelected.touched)" class="text text-danger">
                    <div *ngIf="payoutForm.controls.memberSelected.errors.required">
                      Member must be selected
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mb-3">
                  <label for="unitName">UNIT NAME</label><span class="text-danger">*</span>
                  <input #formField pInputText id="unitName" type="text" class="form-control-prime" formControlName="unitName" name="unitName" [readonly]="true" />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mb-3">
                  <label for="requestedDate">REQUESTED DATE</label><span class="text-danger">*</span>
                  <p-calendar #formField class="form-control-prime" readonlyInput=true  formControlName="requestedDate" [showIcon]="true" [iconDisplay]="'input'" inputId="buttondisplay" dateFormat="dd MM yy" name="requestedDate" appendTo="body" />
                  <div *ngIf="payoutForm.controls.requestedDate.invalid && (payoutForm.controls.requestedDate.dirty || payoutForm.controls.requestedDate.touched)" class="text text-danger">
                    <div *ngIf="payoutForm.controls.requestedDate.errors.required">
                      Requested date must is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mb-3">
                  <label for="payoutEvent">REQUEST TYPE</label><span class="text-danger">*</span>
                  <p-dropdown  #formField id="payoutEvent" optionLabel="name" [options]="payoutEvents" formControlName="payoutEvent" class="form-control-prime" appendTo="body"></p-dropdown>
                  <div *ngIf="payoutForm.controls.payoutEvent.invalid && (payoutForm.controls.payoutEvent.dirty || payoutForm.controls.payoutEvent.touched)" class="text text-danger">
                    <div *ngIf="payoutForm.controls.payoutEvent.errors.required">
                      Request Type must is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="!editing || selectedPayout.status === 'Paid' || selectedPayout.status === 'Requested'">
                <div class="col-md-12 mb-3">
                  <label for="amountPaid">AMOUNT PAID</label><span class="text-danger">*</span>
                  <p-inputNumber inputId="amountPaid" class="form-control-prime" formControlName="amountPaid" mode="decimal" [min]="1"  [showButtons]="false" />
                  <div *ngIf="payoutForm.controls.amountPaid.invalid && (payoutForm.controls.amountPaid.dirty || payoutForm.controls.amountPaid.touched)" class="text text-danger">
                    <div *ngIf="payoutForm.controls.amountPaid.errors.required">
                      Amount Paid must be greater that Zero.
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="!editing || selectedPayout.status === 'Paid' || selectedPayout.status === 'Requested'">
                <div class="col-md-12 mb-3">
                  <label for="paymentDate">PAYMENT DATE</label><span class="text-danger">*</span>
                  <p-calendar #formField class="form-control-prime" readonlyInput=true  formControlName="paymentDate" [showIcon]="true" [iconDisplay]="'input'" inputId="buttondisplay" dateFormat="dd MM yy" name="paymentDate" appendTo="body" />
                  <div *ngIf="payoutForm.controls.paymentDate.invalid && (payoutForm.controls.paymentDate.dirty || payoutForm.controls.paymentDate.touched)" class="text text-danger">
                    <div *ngIf="payoutForm.controls.paymentDate.errors.required">
                      Payment Date is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="!editing || selectedPayout.status === 'Paid' || selectedPayout.status === 'Requested'">
                <div class="col-md-12 mb-3">
                  <label for="paymentType">PAYMENT TYPE</label><span class="text-danger">*</span>
                  <p-dropdown  #formField id="paymentType" optionValue="id" optionLabel="label" [options]="paymentTypes" formControlName="paymentType" class="form-control-prime" appendTo="body"></p-dropdown>
                  <div *ngIf="payoutForm.controls.paymentType.invalid && (payoutForm.controls.paymentType.dirty || payoutForm.controls.paymentType.touched)" class="text text-danger">
                    <div *ngIf="payoutForm.controls.paymentType.errors.required">
                      Payment Type is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="!editing || selectedPayout.status === 'Paid' || selectedPayout.status === 'Requested'">
                <div class="col-md-12 mb-3">
                  <label for="paymentType">PAYMENT REF. #</label>
                  <input #formField pInputText id="paymentReferenceNumber" type="text" class="form-control-prime" formControlName="paymentReferenceNumber" name="paymentReferenceNumber" [readonly]="false" />
                </div>
              </div>
              <div class="row" *ngIf="editing && selectedPayout.status === 'Declined'">
                <div class="col-md-12 mb-3">
                  <label for="reason">REASON</label>
                  <textarea #formField id="reason" rows="5" cols="30" pInputTextarea class="form-control-prime" formControlName="reason" name="reason"></textarea>
                </div>
              </div>

              <ng-container>
                <h5 class="p-t-10">ATTACHMENTS</h5>
                <p>
                  Doctor prescription, Member photos, Hospital bills, etc
                </p>
                <p-divider />
                <div class="p-l-10 p-r-10">
                  <input #formAttachments id="formAttachments" multiple="multiple" type="file" (change)="addDocuments($event)" style="display: none;" accept=".jpg, .jpeg,.png,.pdf"/>
                  <div style="text-align: left;display: flex;width: auto;">
                    <div class="p-relative uploaded-images" *ngFor="let attachment of selectedPayoutAttachments;let i = index">
                        <img *ngIf="attachment.contentType !== 'application/pdf'" (click)="openImage(attachment)" style="max-width:100px;max-height:100px" [attr.src]="getImageUrlForAttachment(attachment) | secure | async"
                            alt="Image" />
                        <img *ngIf="attachment.contentType === 'application/pdf'" (click)="downloadAttachment(attachment)" style="max-width:100px;max-height:100px" src="/assets/images/pdf-icon.png" alt="Product Image" />
                        <div class="remove-file" style="padding:0 !important" (click)="removeServerImage(attachment)"><a style="color:red"
                                href="javascript:void(0)"><i
                                    class="icofont icofont-close"></i></a></div>
                    </div>
                    <div class="p-relative uploaded-images" *ngFor="let preview of fileUploads.previews;let i = index">
                      <img *ngIf="fileUploads.attachments[i].type !== 'application/pdf'" style="max-width:100px;max-height:100px" [src]="preview" alt="Product Image" />
                      <img *ngIf="fileUploads.attachments[i].type === 'application/pdf'" style="max-width:100px;max-height:100px" src="/assets/images/pdf-icon.png" alt="Product Image" />
                      <div class="remove-file" style="padding:0 !important" (click)="removeAttachmentImage(attachment)"><a style="color:red"
                        href="javascript:void(0)"><i
                            class="icofont icofont-close"></i></a></div>
                    </div>
                    <span onclick="document.getElementById('formAttachments').click();"class="add-image add-image-1"><i class="fa fa-plus"></i></span>
                  </div>
                </div>
              </ng-container>
              
              
            </div>

            

            <p-divider />

            
          </div>
        </form>
      </ng-template>
      <ng-template pTemplate="footer">
        <button style="background-color: darkred !important;" pButton [raised]="true" (click)="savePayoutDetails()" class="btn btn-primary m-r-5">SAVE DETAILS</button>
        <button style="background-color: darkred !important;" pButton *ngIf="(editing && selectedPayout.status == 'Requested')" [raised]="true" (click)="declineSelectedPayout()" class="btn btn-primary m-r-5">DECLINE PAYMENT</button>
        <button style="background-color: darkgreen !important;" pButton *ngIf="(editing && selectedPayout.status == 'Requested')" [raised]="true" (click)="approveSelectedPayout()" class="btn btn-primary m-r-5">ACCEPT PAYMENT</button>
    </ng-template>
</p-dialog>

<ng-template #template>
  <div class="modal-body" style="display: flex;justify-content: center;max-height:80vh">
      <img [attr.src]="getImageUrlForAttachment(selectedAttachment) | secure | async">
  </div>
</ng-template>
import { liveProductChart } from './../../../shared/data/widgets-chart/chart-widget';
import { data } from './../../../shared/services/comments.service';
import { state } from '@angular/animations';
import { HttpClient, HttpContext, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component,Input, ElementRef, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, lastValueFrom, map } from 'rxjs';
import Helper from 'src/app/helper/Helper';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { IS_UPLOAD_REQUEST, MultipleFileUpload } from 'src/app/types/types';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth-service.service';
import { MenuItem } from 'primeng/api';
import { SplitButton } from 'primeng/splitbutton';
import { error } from 'console';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver';
import { Table } from 'primeng/table';
declare var require
const Swal = require('sweetalert2')

@Component({
  selector: 'app-member-donations',
  templateUrl: './member-donations.component.html',
  styleUrls: ['./member-donations.component.scss']
})
export class MemberDonationsComponent implements OnInit {

  @ViewChild('dt') dataTable: Table;
  @Input() memberUUID : string;
  
  environment = environment
  moment = moment

  allDonations = []
  totalCount = 0;
  selectedPayment=undefined
  viewDialog = false

  constructor(private httpClient: HttpClient, private route: ActivatedRoute, private router: Router, private fb: FormBuilder,private toastrService: ToastrService,
    private authService: AuthService, protected modalService: NgbModal) { }

  ngOnInit() {

  }

  loadData(event: any) {

    // Prepare the parameters for pagination, sorting, and filtering
    const params: any = {
      take: event.rows, // Number of records to take
      skip: event.first, // Starting point
      order: event.sortField || 'id', // Sorting field
      orderDir: event.sortOrder === 1 ? 'ASC' : 'DESC', // Sorting direction
    };

    const httpParams = new HttpParams({ fromObject: params });

    this.httpClient.get(`${environment.serverUrl}/members/${this.memberUUID}/donations`, { params: httpParams }).subscribe(
      (result:any) => {
        this.allDonations = result.data;
        this.totalCount = result.total
      },
      (error) => {
        console.error('Error fetching Unit Payments:', error);
      }
    );
  }

  onRowSelect(event){
    this.selectedPayment = event.data
    this.viewDialog = true
  }

  refreshList(){
    this.loadData(this.dataTable.createLazyLoadMetadata())
  }

  getAmountPaid(donation){
    if( donation.donationType === "Member Donation") return donation.amountPaid

    if( donation.donationType === "Unit Donation") {
      const memberDonation = donation.donatedByUnitMembers.find( dn => dn.member.uuid === this.memberUUID )
      if( memberDonation ){
        return memberDonation.amountPaid
      }
    }
    return "N/A"
  }

  getBackgroundColor(payment){
    if( payment.status === 'Paid'){
      return '#BA8E23'
    }
    else if( payment.status === 'Confirmed'){
      return '#009688'
    }
    else if( payment.status === 'Declined'){
      return '#FF0000'
    }
  }

  getColor(payment){
    if( payment.status === 'Paid'){
      return '#FFF'
    }
    else if( payment.status === 'Confirmed'){
      return '#FFF'
    }
    else if( payment.status === 'Declined'){
      return '#FFF'
    }
  }

}

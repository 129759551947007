<p-table
  #dt
  [value]="unitPayments"
  [paginator]="true"
  [rows]="10"
  [totalRecords]="totalRecords"
  selectionMode="single"
  [(selection)]="selectedMember" 
  [lazy]="true"
  [reorderableColumns]="true"
  [loading]="loading"
  [rowsPerPageOptions]="[10, 20, 50]"
  (onRowSelect)="onRowSelect($event)" 
  (onLazyLoad)="loadTransactions($event)"
  [expandedRowKeys]="expandedRows" 
  scrollHeight="calc(100vh - 405px)"
  [scrollable]="true" 
  styleClass="mt-3"
  dataKey="id"
>
<ng-template pTemplate="caption">
  <div class="flex flex-wrap justify-content-end gap-2">
      <p-button pRipple class="btn-rounded" label="Refresh Data" icon="pi pi-refresh" text (onClick)="refreshList()" />
  </div>
</ng-template>
  <!-- Expand Button Column -->
  <ng-template pTemplate="header">
    <tr>
      <th pFrozenColumn></th>
      <th pFrozenColumn pSortableColumn="unit.name">Unit <p-sortIcon field="unit.name" /></th>
      <th pFrozenColumn pSortableColumn="paymentDate">Payment Date<p-sortIcon field="paymentDate" /></th>
      <th style="text-align:right">Total Deposit Paid</th>
      <th style="text-align:right">Total Subscription Paid</th>
      <th style="text-align:right">Total Amount Paid</th>
      <th style="text-align:left">Paid By</th>
      <th style="text-align:left">Confirmed By</th>
      <th pFrozenColumn >Reference #<p-sortIcon field="transactionReferenceNumber" /></th>
      <th pFrozenColumn pSortableColumn="status">Status<p-sortIcon field="status" /></th>
      <th></th>
    </tr>
    <tr>
      <th></th>
      <th>
        <p-columnFilter *ngIf="units && units.length > 0" field="unitName" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect 
                  appendTo="body"
                  [ngModel]="value" 
                  [options]="units" 
                  placeholder="Any" 
                  (onChange)="filter($event.value)" 
                  optionLabel="name"
                  >
                      <ng-template let-option pTemplate="item">
                          <div class="inline-block vertical-align-middle">
                              <span class="ml-1 mt-1">{{ option.name }}</span>
                          </div>
                      </ng-template>
              </p-multiSelect>
          </ng-template>
        </p-columnFilter>
      </th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th>
        <p-columnFilter 
          matchMode="in"
          [showMenu]="false"
          type="text" 
          field="transactionReferenceNumber" 
          placeholder="Search by Reference #" 
          ariaLabel="Search by Reference #" />
      </th>
      <th>
        <p-columnFilter field="status" matchMode="equals" [showMenu]="false" >
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-dropdown 
                    [ngModel]="value" 
                    [options]="statuses"
                    (onChange)="filter($event.value)" 
                    placeholder="Select One" 
                    appendTo="body"
                    [showClear]="true">
                        <ng-template let-option pTemplate="item">
                            <p-tag 
                                [value]="option.label" 
                                [severity]="option.value" />
                        </ng-template>
                </p-dropdown>
            </ng-template>
        </p-columnFilter>
      </th>
      <th></th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-payment>
    <tr [pSelectableRow]="payment">
      <td pFrozenColumn></td>
      <td pFrozenColumn>{{ payment.unit.name }}</td>
      <td pFrozenColumn>{{ moment(payment.paymentDate).format("Do MMM YYYY") }}</td>
      <td style="text-align:right">{{ payment.totalDepositAmountPaid | currency:'INR':'symbol'}}</td>
      <td style="text-align:right">{{ payment.totalWeeklySubscriptionAmountPaid | currency:'INR':'symbol'}}</td>
      <td style="text-align:right">{{ payment.totalAmountPaid | currency:'INR':'symbol'}}</td>
      <td >{{ payment.createdBy?.name }}</td>
      <td>{{ payment.confirmedBy?.name }}</td>
      <td>{{ payment.transactionReferenceNumber }}</td>
      <td [ngStyle]="{'background-color' : getBackgroundColor(payment), 'color':getColor(payment)}">{{ payment.status.toUpperCase() }}</td>
      <td>
        <div class="flex align-items-center justify-content-center gap-2">
          <!-- <button style="background-color: white !important;color:#000" pButton [raised]="true" (click)="viewTransaction(payment)" class="btn btn-primary m-r-5">VIEW</button> -->
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr  style="height:calc(100vh - 405px)">
        <td colspan="11" style="text-align: center;">There are no unit payments matching your search criteria</td>
    </tr>
  </ng-template>
</p-table>

<p-dialog 
autoZIndex="false"
  baseZIndex ="1000"
  [(visible)]="viewTransactionDialog" 
  [style]="{ width: '650px'}" 
  [header]="'Transaction Details'" 
  (onHide)="viewTransactionDialog=false;"
  [modal]="true" 
  styleClass="p-fluid">
      <ng-template pTemplate="content">
        <div *ngIf="loadingStatusData" style="width:100%;height:400px;background-color: #fff;justify-content: center;align-items: center;display: flex;">
          <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" ariaLabel="loading" />
        </div>
        <div class="view-transaction">
          <div class="vt-container">
            <div class="vt-row">
              <div class="vt-label">UNIT</div>
              <div class="vt-description">{{selectedPayment.unit?.name}}</div>
            </div>
            <div class="vt-row">
                <div class="vt-label">PAYMENT DATE</div>
                <div class="vt-description">{{moment(selectedPayment.paymentDate).format("Do MMM YYYY")}}</div>
            </div>
            <div class="vt-row">
              <div class="vt-label">DEPOSIT PAID</div>
              <div class="vt-description" style="color:darkgreen">{{selectedPayment.totalDepositAmountPaid | currency:'INR':'symbol'}}</div>
            </div>
            <div class="vt-row">
              <div class="vt-label">SUBSCRIPTION PAID</div>
              <div class="vt-description" style="color:darkgreen">{{selectedPayment.totalWeeklySubscriptionAmountPaid | currency:'INR':'symbol'}}</div>
            </div>
            <div class="vt-row">
              <div class="vt-label">TOTAL AMOUNT PAID</div>
              <div class="vt-description" style="color:darkgreen">{{selectedPayment.totalAmountPaid | currency:'INR':'symbol'}}</div>
            </div>
            <div class="vt-row">
              <div class="vt-label">PAYMENT TYPE</div>
              <div class="vt-description">{{selectedPayment.paymentType ? selectedPayment.paymentType?.toUpperCase() : "N/A"}}</div>
            </div>
            <div class="vt-row">
              <div class="vt-label">TRANSACTION #</div>
              <div class="vt-description">{{selectedPayment.transactionReferenceNumber ? selectedPayment.transactionReferenceNumber : "N/A"}}</div>
            </div>
            <div class="vt-row">
              <div class="vt-label">PAYMENT STATUS</div>
              <div class="vt-description w-50 text-end white p-3" style="color:#FFF" [ngClass]="{'bg-green': selectedPayment.status === 'Confirmed', 'bg-red' : selectedPayment.status === 'Paid'}">{{selectedPayment.status == "Paid" ? "PAID / NOT CONFIRMED" : "CONFIRMED" }}</div>
            </div>

            <div class="p-relative uploaded-images" *ngIf="selectedPayment.attachment">
                          <img *ngIf="selectedPayment.attachment.contentType !== 'application/pdf'" (click)="openImage(selectedPayment.attachment)" style="max-width:100px;max-height:100px" [attr.src]="getImageUrlForAttachment(selectedPayment.attachment) | secure | async"
                              alt="Image" />
            </div>
          </div>

          

          <p-divider />

          
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <button style="background-color: darkred !important;" pButton *ngIf="(selectedPayment.status == 'Paid')" [raised]="true" (click)="declineSelectedPayment()" class="btn btn-primary m-r-5">DECLINE PAYMENT</button>
        <button style="background-color: darkgreen !important;" pButton *ngIf="(selectedPayment.status == 'Paid')" [raised]="true" (click)="confirmSelectedPayment()" class="btn btn-primary m-r-5">ACCEPT PAYMENT</button>
    </ng-template>
</p-dialog>

<ng-template #template>
  <div class="modal-body" style="display: flex;justify-content: center;max-height:80vh">
      <img [attr.src]="getImageUrlForAttachment(selectedAttachment) | secure | async">
  </div>
</ng-template>
import { Component, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-document-viewer-with-preview',
  templateUrl: './document-viewer-with-preview.component.html',
  styleUrls: ['./document-viewer-with-preview.component.css']
})
export class DocumentViewerWithPreviewComponent implements OnInit {

  @Input() documents: boolean = false;
  
  constructor() { 

  }

  ngOnInit() {

  }

}

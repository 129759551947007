<p-table
  #dt
  [value]="allChildren"
  [paginator]="true"
  [rows]="10"
  [totalRecords]="totalRecords"
  selectionMode="single"
  [lazy]="true"
  [reorderableColumns]="true"
  [rowsPerPageOptions]="[10, 20, 50]"
  (onLazyLoad)="loadData($event)"
  scrollHeight="calc(100vh - 405px)"
  [scrollable]="true" 
  styleClass="mt-3"
  dataKey="id"
>
<ng-template pTemplate="caption">
  <div class="flex flex-wrap justify-content-end gap-2">
      <p-button pRipple class="btn-rounded" label="Refresh Data" icon="pi pi-refresh" text (onClick)="refreshList()" />
  </div>
</ng-template>
  <!-- Expand Button Column -->
  <ng-template pTemplate="header">
    <tr>
      <th>Name</th>
      <th>Qualification</th>
      <th>Age</th>
      <th>Member Name</th>
      <th>Member Status</th>
      <th>Relation</th>
    </tr>
    <tr>
      <th>
          <p-columnFilter 
          matchMode="in"
          [showMenu]="false"
          type="text" 
          field="name" 
          placeholder="Search by name" 
          ariaLabel="Filter Name" />
      </th>
      <th>
        <p-columnFilter *ngIf="standardTypes && standardTypes.length > 0" field="standardType" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-dropdown 
              [ngModel]="value" 
              [options]="standardTypes" 
              placeholder="Any" 
              (onChange)="filter($event.value)" 
              optionLabel="label"
              appendTo="body">
            </p-dropdown> 
          </ng-template>
        </p-columnFilter>
      </th>
      <th></th>
      <th>
        <p-columnFilter 
        matchMode="in"
        [showMenu]="false"
        type="text" 
        field="parentName" 
        placeholder="Search by parent name" 
        ariaLabel="Filter Name" />
      </th>
      <th>
        <p-columnFilter field="status" matchMode="equals" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-dropdown 
                    [ngModel]="value" 
                    [options]="statuses"
                    (onChange)="filter($event.value)" 
                    placeholder="Select One" 
                    [showClear]="true"
                    appendTo="body">
                </p-dropdown>
            </ng-template>
        </p-columnFilter>
      </th>
      <th></th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-data>
    <tr [pSelectableRow]="data">
      <td>{{ data.name }}</td>
      <td>{{ getQualification(data.educationalQualification) }}</td>
      <td>{{ data.age }}</td>
      <td>{{ data.member?.name }}</td>
      <td><p-tag 
        [value]="data.member.status.toUpperCase()" 
        [severity]="getSeverity(data.member.status)" /></td>
      <td>{{ data.relation }}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr  style="height:calc(100vh - 405px)">
        <td colspan="11" style="text-align: center;">There are no children matching your search criteria</td>
    </tr>
  </ng-template>
</p-table>

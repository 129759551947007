import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid'; // Import the dayGridPlugin
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-list-all-payments',
  templateUrl: './list-all-payments.component.html',
  styleUrls: ['./list-all-payments.component.scss']
})
export class ListAllPaymentsComponent implements OnInit {
  displayedSundays: any[] = []; 
  totalRecords = 0
  currentPage: number = 0;
  pageSize: number = 8;
  loading = false

  constructor(private http: HttpClient, private router: Router) {}

  ngOnInit(): void {
    this.loadPayments({
      take: this.pageSize,
      skip: 0,
    });
  }

  loadPayments(event: any) {
    this.loading = true;

    // Prepare the parameters for pagination, sorting, and filtering
    const params: any = {
      take: event.take, // Number of records to take
      skip: event.skip, // Starting point
    };

    // // Apply filters dynamically if they exist
    // if (event.filters.name && event.filters.name.value) {
    //   params.name = event.filters.name.value;
    // }
    // if (event.filters.mobileNumber && event.filters.mobileNumber.value) {
    //   params.mobileNumber = event.filters.mobileNumber.value;
    // }
    // if (event.filters.membershipNumber && event.filters.membershipNumber.value) {
    //   params.membershipNumber = event.filters.membershipNumber.value;
    // }
    // if (event.filters.associationMembershipNumber && event.filters.associationMembershipNumber.value) {
    //   params.associationMembershipNumber = event.filters.associationMembershipNumber.value;
    // }
    // if (event.filters.unitName && event.filters.unitName.value) {
    //   params.units = event.filters.unitName.value.map(a => a.uuid).join(",")
    // }
    // if (event.filters.constituencyName && event.filters.constituencyName.value) {
    //   params.constituencies = event.filters.constituencyName.value.map(a => a.uuid).join(",")
    // }
    // if (event.filters.districtName && event.filters.districtName.value) {
    //   params.districts = event.filters.districtName.value.map(a => a.uuid).join(",")
    // }
    // if (event.filters.status && event.filters.status.value) {
    //   params.status = event.filters.status.value;
    // }

    const httpParams = new HttpParams({ fromObject: params });

    this.http.get(`${environment.serverUrl}/payments`, { params: httpParams }).subscribe(
      (result:any) => {
        this.displayedSundays = result.data;
        this.displayedSundays.map (mem =>{
          mem.paymentDate = moment(mem.paymentDate)
        })
        this.totalRecords = result.count;
        this.loading = false;
      },
      (error) => {
        console.error('Error fetching members:', error);
        this.loading = false;
      }
    );
  }

  onPaymentSelected(payment){
    this.router.navigate(['payments','view-payment',payment.uuid])
  }
  
  onPageChange(event: any): void {
    this.loadPayments({
      take: this.pageSize,
      skip: event.page * this.pageSize,
    })
  }

  getStatusNameForPayment(payment){
    if( payment.status === "UnConfirmed Entries"){
      return "UNCONFIRMED ENTRIES"
    }
    else if( payment.status === "Some Unpaid"){
      return "FEW UNPAID ENTRIES"
    }
    else if( payment.status === "No Payments"){
      return "NONE PAID"
    }
    else if( payment.status === "All Confirmed"){
      return "ALL PAID & CONFIRMED"
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListMembersComponent } from './list-members/list-members.component';
import { AddMemberComponent } from './add-member/add-member.component';
import { MemberPaymentsComponent } from './member-payments/member-payments.component';
import { MemberPayoutsComponent } from './member-payouts/member-payouts.component';
import { MemberDonationsComponent } from './member-donations/member-donations.component';
import { MemberTimelineComponent } from './member-timeline/member-timeline.component';
import { TableModule } from 'primeng/table'
import { ToolbarModule } from 'primeng/toolbar'
import { ButtonModule } from 'primeng/button'
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RippleModule } from 'primeng/ripple';
import { AvatarModule } from 'primeng/avatar';
import { MultiSelectModule } from 'primeng/multiselect';
import { TagModule } from 'primeng/tag';
import { DropdownModule } from 'primeng/dropdown';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { SharedModule } from '../../shared/shared.module';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { FieldsetModule } from 'primeng/fieldset';
import { DividerModule } from 'primeng/divider';
import { TimelineModule } from 'primeng/timeline';
import { InputNumberModule } from 'primeng/inputnumber';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputMaskModule } from 'primeng/inputmask';
import { DialogModule } from 'primeng/dialog';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TabViewModule } from 'primeng/tabview';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessagesModule } from 'primeng/messages';

@NgModule({
  imports: [
    CommonModule,
    TableModule,
    ProgressSpinnerModule,
    ToolbarModule,
    ButtonModule,
    RippleModule,
    MultiSelectModule,
    AvatarModule,
    TagModule,
    TimelineModule,
    DropdownModule,
    AvatarGroupModule,
    CardModule,
    InputTextModule,
    InputTextareaModule,
    CalendarModule,
    CheckboxModule,
    FieldsetModule,
    DividerModule,
    InputNumberModule,
    MessagesModule,
    InputGroupModule,
    InputGroupAddonModule,
    AutoCompleteModule,
    DialogModule,
    TabViewModule,
    SplitButtonModule,
    FormsModule,
    InputMaskModule,
    ReactiveFormsModule,
    SharedModule
  ],
  declarations: [ListMembersComponent, AddMemberComponent, MemberPaymentsComponent, MemberPayoutsComponent,MemberDonationsComponent,MemberTimelineComponent]
})
export class MemberModule { }

import { Attachment, IS_UPLOAD_REQUEST, MultipleFileUpload } from './../../../types/types';
import { HttpClient, HttpContext, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Component, ElementRef, HostListener, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Table } from 'primeng/table';
import { lastValueFrom, combineLatest, map } from 'rxjs';
import { ColumnFilter } from 'primeng/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Helper from 'src/app/helper/Helper';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { saveAs } from 'file-saver';
declare var require
const Swal = require('sweetalert2')

@Component({
  selector: 'app-list-unit-donation-types',
  templateUrl: './list-unit-donation-types.component.html',
  styleUrls: ['./list-unit-donation-types.component.scss']
})

export class ListUnitDonationTypesComponent implements OnInit {

  @ViewChild('dt') dataTable: Table;

  allUnitDonationTypes: any[] = [];
  totalRecords: number = 0;
  viewDialog = false
  form: FormGroup = undefined; // Define the form group

  constructor(private httpClient: HttpClient, private route: ActivatedRoute, protected modalService: NgbModal,private fb: FormBuilder) { }

  ngOnInit() {
    

  }

  loadData(event: any) {

    const params: any = {
      take: event.rows, // Number of records to take
      skip: event.first, // Starting point
      order: event.sortField || 'id', // Sorting field
      orderDir: event.sortOrder === 1 ? 'DESC' : 'ASC', // Sorting direction
    }
    
    const httpParams = new HttpParams({ fromObject: params });

    this.httpClient.get(`${environment.serverUrl}/helper/unit-donation-types/`, { params: httpParams }).subscribe(
      (result:any) => {
        this.allUnitDonationTypes = result.data;
        this.totalRecords = result.count;
        
      },
      (error) => {
        console.error('Error fetching Unit Donation Types:', error);
      }
    );
  }

  addNew(){
    this.viewDialog = true
    this.form = this.fb.group({
      name: ['',Validators.required ],
    });
  }

  onRowEditInit(data: any) {
    this.form = this.fb.group({
      name: [data.name,Validators.required ],
      uuid: [data.uuid],
    });
    this.viewDialog = true
  }

  refreshList(){
    if (this.dataTable) {
      this.loadData(this.dataTable.createLazyLoadMetadata());
   }
  }

  save(){

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      Helper.showMessageAlert('Error',`Please fill in the name before saving.`,'error');
      return;
    }

    this.httpClient.post(`${environment.serverUrl}/helper/unit-donation-types`, this.form.value).subscribe({
        next: (v:any) => {
          console.log(v)
          Helper.showMessageAlert('Success','Unit Donation types Details saved successfully!','success');
          this.viewDialog = false
          this.loadData(this.dataTable.createLazyLoadMetadata())
        },
        error: (e) => {
          console.error(e);
          Helper.processError(e);
        },
        complete: () => console.info('complete')
      });

  }

}

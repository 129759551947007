<div class="view-payment-container">
  <section class="pageSection">
    <div class="two">
      <h1>UNCONFIRMED TRANSACTIONS FOR {{paymentDate ? moment(paymentDate).format("Do MMM YYYY").toUpperCase() : ""}}
        <span>YOU HAVE TO CONFIRM THE STATUS of BELOW TRANSACTIONS</span>
      </h1>
    </div>
    <div class="sundays-grid p-l-40 p-r-40 p-t-30">
      <div class="per-card-3" *ngFor="let weeklyPayment of unconfirmed; let i = index;" [ngClass]="{'bg-orange': weeklyPayment.status === 'Yet To Pay', 'bg-green' : weeklyPayment.status === 'Confirmed', 'bg-red' : weeklyPayment.status === 'Paid'}">
        <ng-template *ngTemplateOutlet="tplPaymentCard; context: {$implicit: weeklyPayment}"></ng-template>
      </div>
    </div>
  </section>
  <p-divider />
  <section class="pageSection">
    <div class="two">
      <h1>UNPAID TRANSACTIONS FOR {{paymentDate ? moment(paymentDate).format("Do MMM YYYY").toUpperCase() : ""}}
        <span>TALK TO THE UNIT SECRETARY</span>
      </h1>
    </div>
    <div class="sundays-grid p-l-40 p-r-40 p-t-30">
      <div class="per-card-3" *ngFor="let weeklyPayment of unpaid; let i = index;" [ngClass]="{'bg-orange': weeklyPayment.status === 'Yet To Pay', 'bg-green' : weeklyPayment.status === 'Confirmed', 'bg-red' : weeklyPayment.status === 'Paid'}">
        <ng-template *ngTemplateOutlet="tplPaymentCard; context: {$implicit: weeklyPayment}"></ng-template>
      </div>
    </div>
  </section>
  <p-divider />
  <section class="pageSection">
    <div class="two">
      <h1>CONFIRMED TRANSACTIONS FOR {{paymentDate ? moment(paymentDate).format("Do MMM YYYY").toUpperCase() : ""}}
        <span>BELOW TRANSACTIONS ARE ALREADY CONFIRMED</span>
      </h1>
    </div>
    <div class="sundays-grid p-l-40 p-r-40 p-t-30">
      <div class="per-card-3" *ngFor="let weeklyPayment of confirmed; let i = index;" [ngClass]="{'bg-orange': weeklyPayment.status === 'Yet To Pay', 'bg-green' : weeklyPayment.status === 'Confirmed', 'bg-red' : weeklyPayment.status === 'Paid'}">
        <ng-template *ngTemplateOutlet="tplPaymentCard; context: {$implicit: weeklyPayment}"></ng-template>
      </div>
    </div>
  </section>
</div>


<ng-template #tplPaymentCard let-payment>
  <div class="card-image">
    <span></span>
    <span class="per-name">{{payment.unit.name}}</span>
  </div>

  <div class="card-content">
    <span class="per-position" [ngClass]="{'orange': payment.status === 'Yet To Pay', 'green' : payment.status === 'Confirmed', 'red' : payment.status === 'Paid'}">{{payment.status === 'Yet To Pay' ? 'UNPAID' : (payment.status === 'Confirmed' ? 'CONFIRMED' : 'UNCONFIRMED')}}</span>
    <div class="card-text">
      <div class="item">
        <span>
          TOTAL DUE
        </span>
        <span style="display: block;padding-left:10px;">
          {{ payment.totalAmountDue | currency:'INR':'symbol' }}
        </span>
      </div>
      <div style="border-left: 1px solid #F0F0F0;position: absolute;height:50%;top:25%;left: 50%;"></div>
      <div class="item" style="text-align: end;">
        <span>
          TOTAL COLLECTED
        </span>
        <span style="display: block;">
          {{ payment.status === 'Confirmed' ? (payment.totalAmountPaid | currency:'INR':'symbol') : 'N/A' }}
        </span>
      </div>
    </div>
    <h5 class="p-t-20">PAYMENT DETAILS</h5>
    <p-divider />
    <div class="d-flex flex-row">
      <span class="w-50 text-start">
        Payment Date
      </span>
      <span class="w-50 text-end ">
        <strong>{{payment.unitPayments && payment.unitPayments.length > 0 ? moment(payment.unitPayments[0].paymentDate).format("Do MMM YYYY") : "N/A"}}</strong>
      </span>
    </div>
    <div class="d-flex flex-row">
      <span class="w-50 text-start">
        Amount Paid
      </span>
      <span class="w-50 text-end ">
      <strong>{{payment.unitPayments && payment.unitPayments.length > 0 ? ( payment.unitPayments[0].totalAmountPaid | currency:'INR':'symbol' ): "N/A"}}</strong>
      </span>
    </div>
    <div class="d-flex flex-row">
      <span class="w-50 text-start">
        Transaction #
      </span>
      <span class="w-50 text-end ">
      <strong>{{payment.unitPayments && payment.unitPayments.length > 0 ? payment.unitPayments[0].transactionReferenceNumber : "N/A"}}</strong>
      </span>
    </div>
    <p-divider />
    <div class="d-flex flex-row">
      <button *ngIf="payment.status === 'Paid' || payment.status === 'PaiConfirmed'" style="background-color: #FFF;color:#000" pButton type="submit" [raised]="true" (click)="viewTransaction(payment)" class="w-100 no-background btn btn-primary-outline">VIEW TRANSACTION</button>
    </div>
  </div>
  
</ng-template>

<p-dialog 
autoZIndex="false"
  baseZIndex ="800"
  [(visible)]="viewTransactionDialog" 
  [style]="{ width: '650px'}" 
  [header]="'Transaction Details'" 
  (onHide)="viewTransactionDialog=false;"
  [modal]="true" 
  styleClass="p-fluid">
      <ng-template pTemplate="content">
        <div *ngIf="loadingStatusData" style="width:100%;height:400px;background-color: #fff;justify-content: center;align-items: center;display: flex;">
          <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" ariaLabel="loading" />
        </div>
        <div class="view-transaction">
          <div class="vt-container">
            <div class="vt-row">
              <div class="vt-label">UNIT</div>
              <div class="vt-description">{{selectedPayment.unit?.name}}</div>
            </div>
            <div class="vt-row">
                <div class="vt-label">PAYMENT DATE</div>
                <div class="vt-description">{{moment(selectedPayment.paymentDate).format("Do MMM YYYY")}}</div>
            </div>
            <div class="vt-row">
              <div class="vt-label">DEPOSIT PAID</div>
              <div class="vt-description" style="color:darkgreen">{{selectedPayment.unitPayments && selectedPayment.unitPayments.length > 0 ? ( selectedPayment.unitPayments[0].totalDepositAmountPaid | currency:'INR':'symbol') : "N/A" }}</div>
            </div>
            <div class="vt-row">
              <div class="vt-label">SUBSCRIPTION PAID</div>
              <div class="vt-description" style="color:darkgreen">{{selectedPayment.unitPayments && selectedPayment.unitPayments.length > 0 ? ( selectedPayment.unitPayments[0].totalWeeklySubscriptionAmountPaid | currency:'INR':'symbol') : "N/A"}}</div>
            </div>
            <div class="vt-row">
              <div class="vt-label">TOTAL AMOUNT PAID</div>
              <div class="vt-description" style="color:darkgreen">{{selectedPayment.unitPayments && selectedPayment.unitPayments.length > 0 ? ( selectedPayment.unitPayments[0].totalAmountPaid | currency:'INR':'symbol') : "N/A"}}</div>
            </div>
            <div class="vt-row">
              <div class="vt-label">PAYMENT TYPE</div>
              <div class="vt-description">{{selectedPayment.unitPayments && selectedPayment.unitPayments.length > 0 ? selectedPayment.unitPayments[0].paymentType?.toUpperCase() : "N/A"}}</div>
            </div>
            <div class="vt-row">
              <div class="vt-label">TRANSACTION #</div>
              <div class="vt-description">{{selectedPayment.unitPayments && selectedPayment.unitPayments.length > 0 ? selectedPayment.unitPayments[0].transactionReferenceNumber : "N/A"}}</div>
            </div>
            <div class="vt-row">
              <div class="vt-label">PAYMENT STATUS</div>
              <div class="vt-description w-50 text-end white p-3" [ngClass]="{'bg-green': selectedPayment.unitPayments && selectedPayment.unitPayments.length > 0 && selectedPayment.unitPayments[0].status === 'Confirmed', 'bg-red' : selectedPayment.unitPayments && selectedPayment.unitPayments.length > 0 && selectedPayment.unitPayments[0].status === 'Paid'}">{{selectedPayment.unitPayments?.[0]?.status == "Paid" ? "PAID / NOT CONFIRMED" : "CONFIRMED" }}</div>
            </div>

            <div class="p-relative uploaded-images" *ngIf="selectedPayment.unitPayments && selectedPayment.unitPayments.length > 0 && selectedPayment.unitPayments[0].attachment">
                          <img *ngIf="selectedPayment.unitPayments[0].attachment.contentType !== 'application/pdf'" (click)="openImage(selectedPayment.unitPayments[0].attachment)" style="max-width:100px;max-height:100px" [attr.src]="getImageUrlForAttachment(selectedPayment.unitPayments[0].attachment) | secure | async"
                              alt="Image" />
            </div>
          </div>

          

          <p-divider />

          
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <button style="background-color: darkred !important;" pButton *ngIf="(selectedPayment.unitPayments?.[0]?.status == 'Paid')" [raised]="true" (click)="declineSelectedPayment()" class="btn btn-primary m-r-5">DECLINE PAYMENT</button>
        <button style="background-color: darkgreen !important;" pButton *ngIf="(selectedPayment.unitPayments?.[0]?.status == 'Paid')" [raised]="true" (click)="confirmSelectedPayment()" class="btn btn-primary m-r-5">ACCEPT PAYMENT</button>
    </ng-template>
</p-dialog>

<ng-template #template>
  <div class="modal-body" style="display: flex;justify-content: center;max-height:80vh">
      <img [attr.src]="getImageUrlForAttachment(selectedAttachment) | secure | async">
  </div>
</ng-template>
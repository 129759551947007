import { liveProductChart } from './../../../shared/data/widgets-chart/chart-widget';
import { data } from './../../../shared/services/comments.service';
import { state } from '@angular/animations';
import { HttpClient, HttpContext, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component,Input, ElementRef, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, lastValueFrom, map } from 'rxjs';
import Helper from 'src/app/helper/Helper';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { IS_UPLOAD_REQUEST, MultipleFileUpload } from 'src/app/types/types';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth-service.service';
import { MenuItem } from 'primeng/api';
import { SplitButton } from 'primeng/splitbutton';
import { error } from 'console';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver';
import { Table } from 'primeng/table';
declare var require
const Swal = require('sweetalert2')

@Component({
  selector: 'app-member-timeline',
  templateUrl: './member-timeline.component.html',
  styleUrls: ['./member-timeline.component.scss']
})
export class MemberTimelineComponent implements OnInit {

  @Input() memberUUID : string;
  
  environment = environment
  moment = moment

  allStatuses = []
  totalCount = 0;

  constructor(private httpClient: HttpClient, private route: ActivatedRoute, private router: Router, private fb: FormBuilder,private toastrService: ToastrService,
    private authService: AuthService, protected modalService: NgbModal) { }

  ngOnInit() {
    this.loadData()
  }

  loadData() {

    // Prepare the parameters for pagination, sorting, and filtering
    const params: any = {
      take: 2000, // Number of records to take
      skip: 0, // Starting point
    };

    const httpParams = new HttpParams({ fromObject: params });

    this.httpClient.get(`${environment.serverUrl}/members/${this.memberUUID}/statuses`, { params: httpParams }).subscribe(
      (result:any) => {
        this.allStatuses = result.data;
        this.totalCount = result.total
      },
      (error) => {
        console.error('Error fetching Unit Payments:', error);
      }
    );
  }

  getColor(statusUpdate){
    if( statusUpdate.status === "Member Created") return "#009688"
    if( statusUpdate.status === "No Unit" || statusUpdate.status === "Suspended") return "#FF0000"
    if( statusUpdate.status === "Active") return "#009688"
    if( statusUpdate.status === "Deceased" || statusUpdate.status === "Soft Deceased" || statusUpdate.status === "Retired" || statusUpdate.status === "Soft Retired" || statusUpdate.status === "Deleted" || statusUpdate.status === "Soft Deleted") return "#FF0000"
  }

  getIcon(statusUpdate){
    if( statusUpdate.status === "Member Created") return "pi pi-user-plus"
    if( statusUpdate.status === "No Unit") return "pi pi-sitemap"
    if( statusUpdate.status === "Active") return "pi pi-verified"
    if( statusUpdate.status === "Suspended") return "pi pi-ban"
    if( statusUpdate.status === "Deceased" || statusUpdate.status === "Soft Deceased" || statusUpdate.status === "Retired" || statusUpdate.status === "Soft Retired" || statusUpdate.status === "Deleted" || statusUpdate.status === "Soft Deleted") return "pi pi-user-minus"
  }
}

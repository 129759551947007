<p-table
  #dt
  [value]="allDonations"
  [paginator]="true"
  [rows]="10"
  [totalRecords]="totalRecords"
  selectionMode="single"
  [(selection)]="selectedDonation" 
  [lazy]="true"
  [reorderableColumns]="true"
  [loading]="loading"
  [rowsPerPageOptions]="[10, 20, 50]"
  (onRowSelect)="onRowSelect($event)" 
  (onLazyLoad)="loadDonations($event)"
  scrollHeight="calc(100vh - 405px)"
  [scrollable]="true" 
  styleClass="mt-3"
  dataKey="id"
>
<ng-template pTemplate="caption">
  <div class="flex flex-wrap justify-content-end gap-2">
      <p-button pRipple class="btn-rounded" label="Refresh Data" icon="pi pi-refresh" text (onClick)="refreshList()" />
      <p-button pRipple class="btn-rounded p-button-success" severity="success" label="New Donation" icon="pi pi-plus" text (onClick)="addNewDonation()" />
  </div>
</ng-template>
  <!-- Expand Button Column -->
  <ng-template pTemplate="header">
    <tr>
      <th pFrozenColumn></th>
      <th pFrozenColumn pSortableColumn="donationType">Donation Type<p-sortIcon field="donationType" /></th>
      <th pFrozenColumn style="width:15%" pSortableColumn="unit.name">Unit<p-sortIcon field="unit.name" /></th>
      <th pFrozenColumn pSortableColumn="donatedByMember.name">Member<p-sortIcon field="donatedByMember.name" /></th>
      <th pFrozenColumn pSortableColumn="otherDonatorName">Person<p-sortIcon field="otherDonatorName" /></th>
      <th pFrozenColumn pSortableColumn="unitDonationType.name">Event <p-sortIcon field="unitDonationType.name" /></th>
      <th pFrozenColumn pSortableColumn="amountPaid" style="text-align:right" >Amount Paid<p-sortIcon field="amountPaid" /></th>
      <th>Payment Date</th>
      <th></th>
    </tr>
    <tr>
      <th></th>
      <th>
        <p-columnFilter field="donationType" matchMode="equals" [showMenu]="false" >
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-dropdown 
                  [ngModel]="value" 
                  [options]="donationTypes"
                  (onChange)="filter($event.value)" 
                  placeholder="Select One" 
                  appendTo="body"
                  optionValue="value"
                  [showClear]="true">
                      <ng-template let-option pTemplate="item">
                          <p-tag 
                              [value]="option.label" 
                              [severity]="option.label" />
                      </ng-template>
              </p-dropdown>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter *ngIf="units && units.length > 0" field="unitName" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-multiSelect 
              appendTo="body"
              [ngModel]="value" 
              [options]="units" 
              placeholder="Any" 
              (onChange)="filter($event.value)" 
              optionLabel="name"
              >
                  <ng-template let-option pTemplate="item">
                      <div class="inline-block vertical-align-middle">
                          <span class="ml-1 mt-1">{{ option.name }}</span>
                      </div>
                  </ng-template>
            </p-multiSelect>
         </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter 
          matchMode="in"
          [showMenu]="false"
          type="text" 
          field="name" 
          placeholder="Search by name" 
          ariaLabel="Filter Name" />
      </th>
      <th>
        <p-columnFilter 
          matchMode="in"
          [showMenu]="false"
          type="text" 
          field="otherDonatorName" 
          placeholder="Search by name" 
          ariaLabel="Filter Name" />
      </th>
      <th>
        <p-columnFilter field="unitDonationType" matchMode="equals" [showMenu]="false" >
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-dropdown 
                  [ngModel]="value" 
                  [options]="unitDonationTypes"
                  (onChange)="filter($event.value)" 
                  placeholder="Select One" 
                  optionLabel="name"
                  optionValue="id"
                  appendTo="body"
                  [showClear]="true">
                      <ng-template let-option pTemplate="item">
                          <p-tag 
                              [value]="option.name" 
                              [severity]="option.name" />
                      </ng-template>
              </p-dropdown>
          </ng-template>
        </p-columnFilter>
      </th>
      <th></th>
      <th></th>
      <th></th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-donation>
    <tr [pSelectableRow]="donation">
      <td pFrozenColumn></td>
      <td pFrozenColumn>{{ donation.donationType }}</td>
      <td pFrozenColumn>{{ donation.unit?.name }}</td>
      <td pFrozenColumn>{{ donation.donatedByMember?.name }}</td>
      <td pFrozenColumn>{{ donation.otherDonatorName }}</td>
      <td pFrozenColumn>{{ donation.unitDonationType?.name }}</td>
      <td style="text-align:right">{{ donation.amountPaid | currency:'INR':'symbol'}}</td>
      <td>{{ donation.paymentDate ? moment(donation.paymentDate).format("Do MMM YYYY") : "N/A"}}</td>
      <td>
        <div class="flex align-items-center justify-content-center gap-2">
          <!-- <button style="background-color: white !important;color:#000" pButton [raised]="true" (click)="viewTransaction(payment)" class="btn btn-primary m-r-5">VIEW</button> -->
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr  style="height:calc(100vh - 405px)">
        <td colspan="11" style="text-align: center;">There are no donations matching your search criteria</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="paginatorleft" let-state>

  </ng-template>
  <ng-template pTemplate="paginatorright" let-state>
    <div style="font-weight: bold; padding-right: 120px;">
      Total Amount Received: {{ totalAmountReceived | currency:'INR':'symbol' }}
    </div>
  </ng-template>
</p-table>

<p-dialog 
  autoZIndex="false"
  baseZIndex="1000"
  [(visible)]="viewDonationDialog" 
  [style]="{ width: '900px' }" 
  [header]="'Donation Details'" 
  (onHide)="viewDonationDialog=false;"
  [modal]="true" 
  styleClass="p-fluid">
  
  <ng-template pTemplate="content">
    <div *ngIf="loadingStatusData" style="width:100%; height:400px; background-color: #fff; display: flex; align-items: center; justify-content: center;">
      <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" ariaLabel="loading" />
    </div>
    
    <form [formGroup]="donationForm" (ngSubmit)="save()">
      <div class="view-transaction">
        <div class="vt-container">
          
          <!-- Donation Type and Other Fields -->

          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="donationType">DONATION TYPE</label><span class="text-danger">*</span>
              <p-dropdown [readonly]="editing" id="donationType" formControlName="donationType" [options]="donationTypes" (onChange)="onDonationTypeChange()" placeholder="Select Donation Type"></p-dropdown>
            </div>
          </div>

          <div class="row" *ngIf="donationForm.get('donationType')?.value === 'UnitDonation'">
            <div class="col-md-12 mb-3">
              <label for="unitId">UNIT NAME</label><span class="text-danger">*</span>
              <p-autoComplete [readonly]="editing" id="unitName" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" formControlName="unitName" [suggestions]="filteredUnits" (onSelect)="onUnitSelected($event)" (completeMethod)="filterUnits($event)" field="name" [dropdown]="!editing" class="form-control-prime">
                <ng-template let-city pTemplate="item">
                    <div class="city-item">
                    <div>{{city.name}}</div>
                    </div>
                </ng-template>
              </p-autoComplete>
            </div>
          </div>
        
          <div *ngIf="donationForm.get('donationType')?.value === 'MemberDonation'" class="col-md-12 mb-3">
            <label for="member">MEMBER NAME</label><span class="text-danger">*</span>
            <p-autoComplete  #formField id="member" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" formControlName="member" [suggestions]="filteredMembers" (onSelect)="onMemberSelected($event)" (completeMethod)="filterMembers($event)" field="name" [dropdown]="false" class="form-control-prime" [readonly]="editing" appendTo="body">
              <ng-template let-member pTemplate="item">
                  <div class="member-item">
                  <div>{{member.name}}</div>
                  </div>
              </ng-template>
            </p-autoComplete>
            <div *ngIf="donationForm.controls.memberSelected.invalid && (donationForm.controls.memberSelected.dirty || donationForm.controls.memberSelected.touched)" class="text text-danger">
              <div *ngIf="donationForm.controls.memberSelected.errors.required">
                Member must be selected
              </div>
            </div>
          </div>
          <div *ngIf="donationForm.get('donationType')?.value === 'Others'" class="row">
            <div class="col-md-12 mb-3">
              <label for="otherDonatorName">DONATOR NAME</label>
              <input #formField pInputText id="otherDonatorName" type="text" class="form-control-prime" formControlName="otherDonatorName" name="otherDonatorName" [readonly]="false" />
            </div>
          </div>
          <div class="row" *ngIf="donationForm.get('donationType')?.value === 'UnitDonation'">
            <div class="col-md-12 mb-3">
              <label for="unitId">DONATION EVENT</label>
              <p-dropdown 
                  formControlName="unitDonationTypeId"
                  [options]="unitDonationTypes"
                  placeholder="Select One" 
                  appendTo="body"
                  optionLabel="name"
                  optionValue="id"
                  [showClear]="true">
              </p-dropdown>
            </div>
          </div>

          <!-- Member Contribution Grid (Conditional for Unit Donation) -->
          <div *ngIf="donationForm.get('donationType')?.value === 'UnitDonation' && memberDonations.length > 0" class="p-b-20">
            <p-card header="Member Contributions" subheader="You can add member contributions in the section below.">
              <ng-container formArrayName="memberDonations">
                <ng-container *ngFor="let memberForm of memberDonations.controls; let index = index">
                  <div class="row" [formGroupName]="index">
                    <div class="col-md-6">
                      <label>{{ memberForm.get('memberName')?.value }}</label>
                    </div>
                    <div class="col-md-6">
                      <p-inputNumber formControlName="amountPaid" mode="decimal" [min]="1" placeholder="Contribution Amount"></p-inputNumber>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
          </p-card>
          </div>

          <!-- Other Fields (Amount, Payment Date, Payment Type) -->
          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="amountPaid">AMOUNT PAID</label><span class="text-danger">*</span>
              <p-inputNumber [readonly]="donationForm.get('donationType')?.value === 'UnitDonation'" inputId="amountPaid" class="form-control-prime" formControlName="amountPaid" mode="decimal" [min]="1"></p-inputNumber>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="paymentDate">PAYMENT DATE</label><span class="text-danger">*</span>
              <p-calendar #formField class="form-control-prime" readonlyInput=true  formControlName="paymentDate" [showIcon]="true" [iconDisplay]="'input'" inputId="buttondisplay" dateFormat="dd MM yy" name="paymentDate" appendTo="body" />
              <div *ngIf="donationForm.controls.paymentDate.invalid && (donationForm.controls.paymentDate.dirty || donationForm.controls.paymentDate.touched)" class="text text-danger">
                <div *ngIf="donationForm.controls.paymentDate.errors.required">Payment Date is required</div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="paymentType">PAYMENT TYPE</label><span class="text-danger">*</span>
              <p-dropdown  #formField id="paymentType" optionValue="id" optionLabel="label" [options]="paymentTypes" formControlName="paymentType" class="form-control-prime" appendTo="body"></p-dropdown>
              <div *ngIf="donationForm.controls.paymentType.invalid && (donationForm.controls.paymentType.dirty || donationForm.controls.paymentType.touched)" class="text text-danger">
                <div *ngIf="donationForm.controls.paymentType.errors.required">Payment Type is required</div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="paymentType">PAYMENT REF. #</label>
              <input #formField pInputText id="paymentReferenceNumber" type="text" class="form-control-prime" formControlName="paymentReferenceNumber" name="paymentReferenceNumber" [readonly]="false" />
            </div>
          </div>

          <!-- File Attachments -->
          <ng-container>
            <h5 class="p-t-10">ATTACHMENTS</h5>
            <p>Doctor prescription, Member photos, Hospital bills, etc.</p>
            <p-divider />
            <div class="p-l-10 p-r-10">
              <input #formAttachments id="formAttachments" multiple="multiple" type="file" (change)="addDocuments($event)" style="display: none;" accept=".jpg, .jpeg,.png,.pdf"/>
              <div style="text-align: left;display: flex;width: auto;">
                <div class="p-relative uploaded-images" *ngFor="let attachment of selectedDonationAttachments; let i = index">
                  <img *ngIf="attachment.contentType !== 'application/pdf'" (click)="openImage(attachment)" style="max-width:100px; max-height:100px" [attr.src]="getImageUrlForAttachment(attachment) | secure | async" alt="Image"/>
                  <img *ngIf="attachment.contentType === 'application/pdf'" (click)="downloadAttachment(attachment)" style="max-width:100px; max-height:100px" src="/assets/images/pdf-icon.png" alt="PDF"/>
                  <div class="remove-file" (click)="removeServerImage(attachment)"><a style="color:red" href="javascript:void(0)"><i class="icofont icofont-close"></i></a></div>
                </div>
                <div class="p-relative uploaded-images" *ngFor="let preview of fileUploads.previews;let i = index">
                  <img *ngIf="fileUploads.attachments[i].type !== 'application/pdf'" style="max-width:100px;max-height:100px" [src]="preview" alt="Product Image" />
                  <img *ngIf="fileUploads.attachments[i].type === 'application/pdf'" style="max-width:100px;max-height:100px" src="/assets/images/pdf-icon.png" alt="Product Image" />
                  <div class="remove-file" style="padding:0 !important" (click)="removeAttachmentImage(attachment)"><a style="color:red"
                    href="javascript:void(0)"><i
                        class="icofont icofont-close"></i></a></div>
                </div>
                <span onclick="document.getElementById('formAttachments').click();" class="add-image add-image-1"><i class="fa fa-plus"></i></span>
              </div>
            </div>
          </ng-container>

        </div>
      </div>
    </form>
  </ng-template>

  <ng-template pTemplate="footer">
    <button pButton style="background-color: darkred;" (click)="saveDonation()" class="btn btn-primary m-r-5">SAVE DETAILS</button>
    <button *ngIf="editing && selectedDonation.status == 'Requested'" pButton style="background-color: darkred;" (click)="declineSelectedDonation()" class="btn btn-primary m-r-5">DECLINE PAYMENT</button>
    <button *ngIf="editing && selectedDonation.status == 'Requested'" pButton style="background-color: darkgreen;" (click)="approveSelectedDonation()" class="btn btn-primary m-r-5">ACCEPT PAYMENT</button>
  </ng-template>
</p-dialog>


<ng-template #template>
  <div class="modal-body" style="display: flex;justify-content: center;max-height:80vh">
      <img [attr.src]="getImageUrlForAttachment(selectedAttachment) | secure | async">
  </div>
</ng-template>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { LoaderComponent } from './components/loader/loader.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { DocumentViewerWithPreviewComponent } from './components/document-viewer-with-preview/document-viewer-with-preview.component';
import { ContentLayoutComponent } from './components/layout/content-layout/content-layout.component';
import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { RightSidebarComponent } from './components/right-sidebar/right-sidebar.component';
import { BookmarkComponent } from './components/bookmark/bookmark.component';
import { SecurePipe } from './pipes/secure.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { CustomizerComponent } from './components/customizer/customizer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { DataTablePagerComponent } from './components/pagination/pager.component'
import { CommentComponent } from './components/comments/comment/comment.component';
import { CommentBoxComponent } from './components/comments/comment-box/comment-box.component';
import { CommentsService } from './services/comments.service'
import { CKEditorModule } from 'ng2-ckeditor';
import { NgbRatingModule} from '@ng-bootstrap/ng-bootstrap'; 
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import {BreadcrumbModule} from 'primeng/breadcrumb';

import { TableModule} from 'primeng/table'
import 'hammerjs';
import 'mousetrap';

// services
import { NavService } from '../services/nav-service.service';
import { CustomizerService } from "./services/customizer.service";
// Directives
import { ToggleFullscreenDirective } from "./directives/fullscreen.directive";
import { NgbdSortableHeader } from './directives/NgbdSortableHeader';
import { UserProfileComponent } from './components/user-profile/user-profile.component';


@NgModule({
  declarations: [
    LoaderComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    BookmarkComponent,
    RightSidebarComponent,
    ContentLayoutComponent,
    DocumentViewerWithPreviewComponent,
    FeatherIconsComponent,
    ToggleFullscreenDirective,
    NgbdSortableHeader,
    BreadcrumbComponent,
    DataTablePagerComponent,
    CustomizerComponent,
    CommentComponent, 
    CommentBoxComponent, UserProfileComponent,
    SecurePipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    TranslateModule,
    NgbModule,
    GalleryModule,
    NgbRatingModule,
    BreadcrumbModule,
    CKEditorModule,
    TableModule,
    ProgressSpinnerModule
  ],
  exports: [
    LoaderComponent,
    FeatherIconsComponent,
    TranslateModule,
    DataTablePagerComponent,
    NgbdSortableHeader,
    CommentComponent,
    CommentBoxComponent,
    SecurePipe,
    DocumentViewerWithPreviewComponent,
    NgbRatingModule,
    RouterModule,
  ],
  providers: [
    NavService,
    CustomizerService,
    CommentsService,
  ]
})
export class SharedModule { }


import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, catchError, retry, tap, switchMap, share } from 'rxjs/operators';
import { BehaviorSubject, lastValueFrom, Observable, of as observableOf, of} from 'rxjs';
import { throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { DataSharingService } from './data-sharing-service.service'
import { LocalStorageService } from './local-storage.service';
import { LoginResponse, User } from '../types/types'

@Injectable({
  providedIn: 'root',
})

export class AuthService {

  user$ = new BehaviorSubject(null);

  settings$ = new BehaviorSubject(null);

  showLoader : boolean = false;

  constructor(
    private http: HttpClient,
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    private localStorageService: LocalStorageService
  ) {
  }

  login(email: string, password: string) : Observable<LoginResponse> {

    return this.http.post<any>(`${environment.serverUrl}/auth/login`,{'username':email,'password':password})
    .pipe(
      tap(response => {
        this.user$.next(response.user);
        this.setToken('userId', response.user.id);
        this.setToken('userType', response.user.userType);
        this.setToken('userRole', response.user.role);
        this.setToken('token', response.accessToken);
        this.setToken('refreshToken', response?.refreshToken);
        this.setToken('settings',response?.settings)
      })
    );
  }

  logout(): void {
    this.localStorageService.removeItem('token');
    this.localStorageService.removeItem('refreshToken');
    this.user$.next(null);
  }

  isAuthenticated(): boolean {
    const token = this.getToken("token");
    if( token && token.length > 0 ){
      return this.isValidToken(token);
    }
    else{
      return false;
    }
  }

  isAuthenticatedOrRefresh(): Observable<boolean> {
    const token = this.getToken("token");
    if( token && token.length > 0 ){
      return token
      .pipe(
        switchMap(token => {
        if (token && !this.isValidToken(token)) {
          return this.refreshToken()
            .pipe(
              switchMap(res => {
                return of(this.isAuthenticated());
              }),
            )
        } else {
          return observableOf(this.isValidToken(token));
        }
    }));
    }
    else{
      return of(false);
    }
    
  }

  getCurrentUser(): Observable<User> {
    return this.user$.pipe(
      switchMap(user => {
        // check if we already have user data
        if (user) {
          return of(user);
        }

        const token = this.localStorageService.getItem('token');
        // if there is token then fetch the current user
        if (token && token.length > 0) {
          return this.fetchCurrentUser();
        }

        return of(null);
      }),
      share()
    );
  }

  fetchCurrentUser(): Observable<User> {
    return this.http.get<User>(`${environment.serverUrl}/user/${this.localStorageService.getItem('userId')}`)
      .pipe(
        tap(user => {
          this.user$.next(user);
        }),
        catchError(this.errorHandler.bind(this))
      )   
  }

  errorHandler(error: HttpErrorResponse, caught: Observable<User>) {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 0) {
        this.router.navigate(['/error/503'])
      }
      else if (error.status === 401) {
        this.router.navigate(['/auth/login'])
      }
    }
    return throwError(() => new Error(error.message || "server error."))
  }

  get authToken(): string{
    if( this.isAuthenticated() ){
      return this.getToken('token');
    }
  }

  refreshToken(): Observable<{accessToken: string; refreshToken: string}> {

    const refreshToken = this.localStorageService.getItem('refreshToken');
    return this.http.post<{accessToken: string; refreshToken: string}>(
      `${environment.serverUrl}/refresh-token`,
      {
        refreshToken
      }).pipe(
        tap(response => {
          this.setToken('token', response.accessToken);
          this.setToken('refreshToken', response.refreshToken);
        })
    );
  }

  public redirectToLogin(path: string, queryParams: any) {
    this.router.navigate([path], {
        queryParams,
        queryParamsHandling: "merge",
    });
  }

  private setToken(key: string, token: string): void {
    this.localStorageService.setItem(key, token);
  }

  private getToken(key: string) {
    return this.localStorageService.getItem(key);
  }

  private isValidToken( token ): boolean {
    
    const now = new Date();
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    const nowUTC = new Date(now.getTime() + (now.getTimezoneOffset() * 60000))
    if( (Math.floor((nowUTC).getTime() / 1000)) < expiry ){
      return true;
    }
    return false;
  }

  setUser(user: User){
    this.user$.next(user);
  }
}